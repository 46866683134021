import * as duckdb from "@duckdb/duckdb-wasm";
import * as arrow from "apache-arrow";

type DuckDBQueryOrder = "ASC" | "DESC";
type AllYears = "+ALL";

const JSDELIVR_BUNDLES = duckdb.getJsDelivrBundles();
const OBJECT_STORE_URI =
  process.env.REACT_APP_OBJECT_STORE_URI ||
  "https://object-arbutus.cloud.computecanada.ca/rll-data";
// const TABLES = ["summary", "yearly", "yearly_judge", "rpd"];
const TABLES = ["summary", "yearly", "yearly_judge"];

const tableToJSON = async (table: arrow.Table) => {
  const arr = table.toArray()
  try {
    return arr.map((x) => x.toJSON());
  } catch(err) {
    console.log('Failed to convert table to JSON.')
    console.log(arr);
    return []
  }
}
const fetchQuery = async (conn: duckdb.AsyncDuckDBConnection, stmt: string) => {
  // const res = await conn.query(stmt)
  try {
    const res = await conn.query(stmt);
    // console.log(res);
    const data = await tableToJSON(res);
    if (process.env.NODE_ENV === "development") {
      console.log({ query: stmt, data: data });
    }
    // return res;
    return data
  } catch(err) {
    console.log(`Err (${err}) for query ${stmt}`)
    throw err
    // return []
  }
  // return await tableToJSON(res)
};

export const initializeDB = async () => {
  // https://github.com/llimllib/nbastats/blob/47d7249106d17eadea3edb6ce79001fc5b00f82e/src/viewer.js#L1359
  const bundle = await duckdb.selectBundle(JSDELIVR_BUNDLES);
  const worker_url = URL.createObjectURL(
    new Blob([`importScripts("${bundle.mainWorker}");`], {
      type: "text/javascript",
    })
  );

  // Instantiate the asynchronous version of DuckDB-wasm
  const worker = new Worker(worker_url);
  const logger = new duckdb.ConsoleLogger(
    process.env.NODE_ENV === "development"
      ? duckdb.LogLevel.INFO
      : duckdb.LogLevel.WARNING
  );

  const db = new duckdb.AsyncDuckDB(logger, worker);
  await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
  URL.revokeObjectURL(worker_url);


  const conn = await db.connect();
  await conn.query(`
    SET enable_object_cache=true; 
    SET enable_http_metadata_cache=true;
  `);


  await Promise.all(
    TABLES.map((t) =>
      db
        .registerFileURL(
          `${t}.parquet`,
          `${OBJECT_STORE_URI}/${t}.parquet`,
          duckdb.DuckDBDataProtocol.HTTP,
          false
        )
        .then(() =>
          conn.query(
            `CREATE VIEW IF NOT EXISTS ${t} AS SELECT * FROM '${t}.parquet'`
          )
        )
    )
  );
  // await db.registerFileURL(`rpd.csv`, `${OBJECT_STORE_URI}/rpd.csv`, duckdb.DuckDBDataProtocol.HTTP, false).then(() => conn.query(`CREATE VIEW IF NOT EXISTS rpd AS SELECT * FROM read_csv_auto('rpd.csv')`))

  var myHeaders = new Headers();
  // myHeaders.append('pragma', 'no-cache');
  // myHeaders.append('cache-control', 'no-cache');
  // myHeaders.append('mode', 'no-cors');

  var myInit = {
    method: 'GET',
    headers: myHeaders,
  };

  var myRequest = new Request(`${OBJECT_STORE_URI}/rpd.parquet`);
  const rpdBuff = await fetch(myRequest, myInit);
  if(rpdBuff.ok){
    db.registerFileBuffer('rpd.parquet', new Uint8Array(await rpdBuff.arrayBuffer()));
  
    // await db.registerFileURL(`rpd.parquet`, `${OBJECT_STORE_URI}/rpd_test.parquet`, duckdb.DuckDBDataProtocol.HTTP, false);
    // // await conn.query(`CREATE VIEW IF NOT EXISTS rpd AS SELECT * FROM 'rpd.parquet'`);
    await conn.query(`CREATE OR REPLACE VIEW rpd AS SELECT * FROM 'rpd.parquet'`);
  }

  if (process.env.NODE_ENV === "development") {
    console.log(await fetchQuery(conn, "SHOW TABLES;"));
  }

  return conn;
};

//// FC Queries ////

// /fc/yearly
export const getYearly = async (
  conn: duckdb.AsyncDuckDBConnection,
  start_year: number | null = null,
  end_year: number | null = null,
  order: DuckDBQueryOrder = "DESC"
) => {
  let stmt = `SELECT * FROM yearly`;
  if (start_year) {
    if (end_year) {
      stmt = `${stmt} WHERE year BETWEEN ${start_year} AND ${end_year}`;
    } else {
      stmt = `${stmt} WHERE year=${start_year}`;
    }
  }
  stmt = `${stmt} ORDER BY year ${order}`;
  return await fetchQuery(conn, stmt);
};

// /fc/yearly/judge
export const getYearlyJudge = async (
  conn: duckdb.AsyncDuckDBConnection,
  name: string | null = null,
  year: number | null = null,
  order: DuckDBQueryOrder = "DESC"
) => {
  const cond = [];
  if (name) {
    cond.push([`judge='${name}'`]);
  }
  if (year) {
    cond.push([`year=${year}`]);
  }

  const stmt = `
    SELECT *
    FROM yearly_judge
    ${cond.length > 0 ? `WHERE ${cond.join(" AND ")}` : ""}
    ORDER BY year ${order}`;

  return await fetchQuery(conn, stmt);
};

// /fc/summary
export const getSummary = async (
  conn: duckdb.AsyncDuckDBConnection,
  start_year: number | null = null,
  end_year: number | null = null
) => {
  const cond =
    start_year && end_year
      ? `WHERE year BETWEEN ${start_year} AND ${end_year}`
      : "";
  const stmt = `
    WITH agg AS (
        SELECT 
            FSUM(leave_granted) AS leave_granted,
            FSUM(leave_jr_granted) AS leave_jr_granted,
            FSUM(leave_total) AS leave_total,
            FSUM(merits_jr_granted) AS merits_jr_granted,
            FSUM(merits_total) AS merits_total,
            FSUM(leave_pending_jr) AS leave_pending_jr
        FROM yearly
        ${cond}
    )
    SELECT 
        *,
        leave_granted / leave_total AS leave_rate,
        merits_jr_granted / merits_total AS merits_rate,
        leave_jr_granted / (leave_total - leave_pending_jr) AS overall_rate 
    FROM agg;
    `;
  return (await fetchQuery(conn, stmt))[0];
};

// /fc/summary/judges
export const getSummaryJudge = async (
  conn: duckdb.AsyncDuckDBConnection,
  name: string | null = null
) => {
  const cond = name ? `WHERE judge = '${name}'` : "";
  const stmt = `
        SELECT * 
        FROM summary
        ${cond}
    `;
  return await fetchQuery(conn, stmt);
};

// /fc/list/years
export const getDistinctYears = async (conn: duckdb.AsyncDuckDBConnection) => {
  const stmt = "SELECT DISTINCT year::INT AS year FROM yearly;";
  const res = await fetchQuery(conn, stmt);
  return res.map((r) => r.year);
};

// /fc/list/judges
export const getDistinctJudges = async (conn: duckdb.AsyncDuckDBConnection) => {
  const stmt = `SELECT DISTINCT judge FROM summary WHERE judge <> '<unknown>' AND judge IS NOT NULL;`;
  const res = await fetchQuery(conn, stmt);
  return res.map((r) => r.judge);
};

//// RPD Queries ////

// /rpd/countries
export const getRpdCountries = async (
  conn: duckdb.AsyncDuckDBConnection,
  id: string,
  year: number | null,
  order: DuckDBQueryOrder = "DESC"
) => {
  const stmt = `
    SELECT *
    FROM rpd
    WHERE 
        member_id='${id}' AND
        ${year === null ? "year is NULL" : `year=${year}`} AND
        country_id<>'+ALL'
    ORDER BY year ${order};`;
  return await fetchQuery(conn, stmt);
};

// /rpd/member
export const getRpdMember = async (
  conn: duckdb.AsyncDuckDBConnection,
  id: string,
  order: DuckDBQueryOrder = "DESC"
) => {
  const stmt = `
    SELECT *
    FROM rpd
    WHERE member_id='${id}'
    ORDER BY year ${order};`;

  return await fetchQuery(conn, stmt);
};

// /rpd/members
export const getRpdMembers = async (
  conn: duckdb.AsyncDuckDBConnection,
  country: string,
  min_count: number,
  year: AllYears | number | null = null
  // order: DuckDBQueryOrder = "DESC",
) => {
  year = year === "+ALL" ? null : year;
  const stmt = `
    SELECT DISTINCT ON (member_id) *
    FROM rpd
    WHERE 
        member_id<>'+ALL' AND
        country_id='${country}' AND 
        count_min>${min_count} AND
        ${year ? `year=${year}` : "year IS NULL"}
    `;

  return await fetchQuery(conn, stmt);
};

// /rpd/member-list
export const getRpdMemberList = async (
  conn: duckdb.AsyncDuckDBConnection,
  country_id: string,
  min_count: number,
  year: AllYears | number | null = null
  // order: DuckDBQueryOrder = "DESC",
) => {
  year = year === "+ALL" ? null : year;
  const stmt = `
    SELECT DISTINCT ON (member_id) * 
    FROM rpd
    WHERE 
        member_id<>'+ALL' AND
        country_id='${country_id}' AND
        count_min>${min_count} AND
        ${year ? `year=${year}` : "year IS NULL"}
    `;

  return await fetchQuery(conn, stmt);
};

// /rpd/year
export const getRpdYear = async (
  conn: duckdb.AsyncDuckDBConnection,
  country: string,
  year: AllYears | number | null = null,
  order: DuckDBQueryOrder = "DESC"
) => {
  year = year === "+ALL" ? null : year;
  const stmt = `
    SELECT *
    FROM rpd
    WHERE 
        member_id='+ALL' AND
        country_id='${country}' AND
        ${year ? `year=${year}` : "year IS NULL"}
    ORDER BY year ${order};`;

  return await fetchQuery(conn, stmt);
};

// /rpd/years
export const getRpdYears = async (
  conn: duckdb.AsyncDuckDBConnection,
  country: string,
  order: DuckDBQueryOrder = "DESC"
) => {
  const stmt = `
    SELECT *
    FROM rpd
    WHERE 
        member_id='+ALL' AND
        country_id='${country}' AND
        year IS NOT NULL
    ORDER BY year ${order};`;

  return await fetchQuery(conn, stmt);
};

// /rpd/member-summary
export const getRpdMemberSummary = async (
  conn: duckdb.AsyncDuckDBConnection,
  id: string,
  country: string,
  year: AllYears | number | null = null
  // order: DuckDBQueryOrder = "DESC",
) => {
  year = year === "+ALL" ? null : year;
  const stmt = `
    SELECT *
    FROM rpd
    WHERE 
        member_id='${id}' AND
        country_id='${country}' AND
        ${year ? `year=${year}` : "year IS NULL"}
    `;

  return (await fetchQuery(conn, stmt))[0];
};
