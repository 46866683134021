import { Button } from "@mui/material";
import styled from "styled-components";
import { IRBCountry } from "../util/interfaces";
import {
  Action,
  Alert,
  CountryLine,
  Detail,
  Display,
  Subtitle,
} from "../shared/Common";

const Box = styled.div`
  position: relative;
  transition: box-shadow 250ms ease;

  @media (min-width: 600px) {
    :hover {
      box-shadow: var(--shadow-basic);

      .line-left {
        transform: translateX(-16px);
        opacity: 1;
      }
    }
  }
`;

const CountryContainer = styled.div`
  padding: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;

  p,
  b,
  label,
  h1,
  h2 {
    color: var(--light-1);
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: appear 250ms ease;
`;

const Left = styled.div`
  display: grid;
  grid-row-gap: 32px;
`;

const Info = styled.div`
  display: grid;
  grid-row-gap: 16px;

  h2 {
    margin: 0;
  }
`;

const ClaimsBox = styled.div`
  p {
    margin: 0;
  }
`;

const PercentBox = styled.div`
  display: grid;
  grid-row-gap: 8px;
  align-content: flex-end;
  padding-right: 24px;

  h1,
  h2 {
    margin: 0;
  }

  h1,
  h2,
  p {
    justify-self: flex-end;
  }
`;

const Lower = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: var(--light-1);
`;

function IRBCountryCard({
  country,
  rank,
  setCountryFilter,
  isFilter,
}: {
  country: IRBCountry;
  rank: number;
  setCountryFilter: () => void;
  isFilter: boolean;
}) {
  const { name, decisions, rate, avgDecisions } = country;

  return (
    <Box
      style={{
        backgroundColor: rank % 2 === 0 ? "#5B747C" : "#18708C",
      }}
    >
      <CountryContainer>
        <Left>
          <b>{rank}</b>
          <Info>
            <h2>{name}</h2>
            <ClaimsBox>
              <Alert>~{decisions.toLocaleString()}</Alert>
              <Detail>claims</Detail>
            </ClaimsBox>
          </Info>
        </Left>
        <PercentBox>
          <Display>{`${rate}%`}</Display>
          <Subtitle>recognition rate</Subtitle>
        </PercentBox>
      </CountryContainer>
      <Lower>
        <Detail>
          average of {avgDecisions!.toLocaleString()} claims per year
        </Detail>
        {!isFilter && (
          <Button onClick={setCountryFilter} variant="text">
            <Action>apply to filter</Action>
          </Button>
        )}
      </Lower>
      <CountryLine className="line-left" />
    </Box>
  );
}

export default IRBCountryCard;
