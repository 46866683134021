import { Container, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import {
  // RefugeeCase,
  Judge,
  FCYearEntry,
  JudgeEntry,
  FCQueryV2Response,
  FCJudgeQueryResponseV2,
} from "../util/interfaces";
import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import CountUp from "react-countup";
import {
  Action,
  Actions,
  Alert,
  Banner,
  Detail,
  Display,
  LoadingOverlay,
  PageFilterBox,
  Section,
  Title,
  SectionLabel,
  SectionLabelsContainer,
  Stat,
  SubSections,
  Subtitle,
} from "../shared/Common";
import RefugeeJRLeaveRatePerOverallRefugeeJRRateLeaveJudge from "./graphs/DecisionMaker/RefugeeJRLeaveRatePerOverallRefugeeJRRateLeaveJudge";
import RefugeeJRLeaveRatePerYear from "./graphs/DecisionMaker/RefugeeJRLeaveRatePerYear";
// import docketData from "../mock/dockets.json";
import { ALL_INDICATOR } from "../util/constants";
import StatAvg from "./StatAvg";
import FCLeaveRatePerMeritsRate from "./graphs/Federal Court/FCLeaveRatePerMeritsRate";
import { getJudgeImage } from "../util/JudgeImages";
import SectionIndicator from "./SectionIndicator";
import { getJudge, getJudges } from "../util/fc/fc.helpers";
import FCLeaveRateHorizontalGraph from "./graphs/Federal Court/FCLeaveRateHorizontalGraph";
import { parsePercent } from "../util/helpers";
import FCMeritsHorizontalGraph from "./graphs/Federal Court/FCMeritsHorizontalGraph";
import JudgeBio from "./Federal Court/JudgeBio";
import { useDb } from "../services/UseDb";
import { getSummary, getSummaryJudge, getYearlyJudge } from "../services/db";

// const testCases: RefugeeCase[] = [
//   {
//     title: "A v. Canada",
//     id: "FC 1954",
//     year: 2021,
//     link: "case",
//   },
//   {
//     title: "A v. Canada",
//     id: "FC 1954",
//     year: 2021,
//     link: "case",
//   },
//   {
//     title: "A v. Canada",
//     id: "FC 1954",
//     year: 2021,
//     link: "case",
//   },
//   {
//     title: "A v. Canada",
//     id: "FC 1954",
//     year: 2021,
//     link: "case",
//   },
//   {
//     title: "A v. Canada",
//     id: "FC 1954",
//     year: 2021,
//     link: "case",
//   },
// ];

const Layer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 64px 0;
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`;

const PercentStatBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  height: 220px;
  transition: box-shadow 250ms ease;

  :hover {
    box-shadow: var(--shadow-basic);
  }

  @media (max-width: 700px) {
    height: 148px;
  }
`;

const CountStatBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  transition: box-shadow 250ms ease;

  :hover {
    box-shadow: var(--shadow-basic);
  }

  p {
    margin-left: 32px;
  }
`;

const StatTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const KeyStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;

  h2,
  label,
  p {
    color: var(--light-1);
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const TopStatsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 16px;

  @media (max-width: 700px) {
    grid-template-columns: none;
    grid-template-rows: repeat(2, 1fr);
  }
`;

const BottomStatsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;

  @media (max-width: 700px) {
    grid-template-columns: none;
    grid-row-gap: 16px;
    grid-template-rows: repeat(2, 1fr);
  }
`;

const makeBannerMessage = (leaveNum: number, meritsNum: number) => {
  if (leaveNum < 30 && meritsNum < 30) {
    return `This Federal Court justice has only decided ${leaveNum} refugee
    leave applications and ${meritsNum} refugee merits JRs.
    `;
  }
  if (leaveNum < 30) {
    return `This Federal Court justice has only decided ${leaveNum} refugee
    leave applications.
    `;
  }
  if (meritsNum < 30) {
    return `This Federal Court justice has only decided ${meritsNum} refugee
    merits JRs.
    `;
  }
};

function FCJudgeV2({ duckDbConn }: { duckDbConn: AsyncDuckDBConnection }) {
  const { id } = useParams<{ id: string }>();

  // Judge source info
  const [judgesInfo, setJudgesInfo] = useState<Judge[]>([]);
  
  const [info, setInfo] = useState<Judge>({
    bio: "",
    displayName: "",
    id: "",
    imageUrl: "",
    name: "",
    sourceURL: "",
  });
  const [imageURL, setImageURL] = useState<string | null>();

  const [loading, setLoading] = useState(true);

  // Filters for page
  const [showDataFilter, setShowDataFilter] = useState(true);
  const [yearPageFilter, setYearPageFilter] = useState(ALL_INDICATOR);
  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [earliestYear, setEarliestYear] = useState<number>(0);
  const [latestYear, setLatestYear] = useState<number>(0);

  // Summary data
  const [yearSummary, setYearSummary] = useState<FCYearEntry>({
    overall_jr_rate: 0,
    merits_rate: 0,
    jr_leave_rate: 0,
    merits_total: 0,
    leave_total: 0,
    jr_total: 0,
  });
  const [fcYearSummary, setFCYearSummary] = useState<FCYearEntry>({
    overall_jr_rate: 0,
    merits_rate: 0,
    jr_leave_rate: 0,
    merits_total: 0,
    leave_total: 0,
    jr_total: 0,
  });

  // Graph data
  const [graphData, setGraphData] = useState<JudgeEntry[]>([]);
  const [perYearEntries, setPerYearEntries] = useState<FCYearEntry[]>([]);

  // Data queries
  const { loading: yearSummaryDataLoading, data: yearSummaryData } = useDb(
    yearPageFilter === ALL_INDICATOR
      ? getSummaryJudge(
          duckDbConn,
          id.includes("'") ? id.replace("'", "''") : id
        )
      : getYearlyJudge(
          duckDbConn,
          id.includes("'") ? id.replace("'", "''") : id,
          parseInt(yearPageFilter)
        ),
    [yearPageFilter]
  );

  const { loading: fcYearSummaryDataLoading, data: fcYearSummaryData } = useDb(
    getSummary(
      duckDbConn,
      yearPageFilter === ALL_INDICATOR ? null : parseInt(yearPageFilter),
      yearPageFilter === ALL_INDICATOR ? null : parseInt(yearPageFilter)
    ),
    [yearPageFilter]
  );

  const { loading: judgesLoading, data: judgesData } = useDb(
    yearPageFilter === ALL_INDICATOR
      ? getSummaryJudge(duckDbConn, null)
      : getYearlyJudge(duckDbConn, null, parseInt(yearPageFilter)),
    [yearPageFilter]
  );

  const { loading: perYearLoading, data: perYearData } = useDb(
    getYearlyJudge(
      duckDbConn,
      id.includes("'") ? id.replace("'", "''") : id,
      null,
      "ASC"
    ),
    [id]
  );
  // end of data queries

  // const pinCommunity = () => {
  //   setPinnedCommunity(!pinnedCommunity);
  // };

  useEffect(() => {
    const judges = getJudges();
    setJudgesInfo(judges);
  }, []);

  useEffect(() => {
    const judgeInfo = getJudge(id);

    if (!judgeInfo) {
      setInfo({
        name: id,
        displayName: id,
        bio: "",
        imageUrl: "",
        sourceURL: "",
        id,
      });

      return;
    }

    setImageURL(getJudgeImage(id));
    setInfo(judgeInfo);
  }, [id]);

  useEffect(() => {
    if (availableYears.length === 0) {
      setEarliestYear(new Date().getFullYear());
      setLatestYear(new Date().getFullYear());

      return;
    }

    setEarliestYear(Math.min(...availableYears));
    setLatestYear(Math.max(...availableYears));
  }, [availableYears]);

  // Handle of query results
  useEffect(() => {
    if (perYearData) {
      const formattedYears: FCYearEntry[] = perYearData.map(
        ({
          leave_total,
          leave_rate,
          overall_rate,
          jr_granted,
          pending_jr,
          merits_total,
          merits_rate,
          year,
        }: FCQueryV2Response) => ({
          overall_jr_rate: parsePercent(overall_rate),
          merits_rate: parsePercent(merits_rate),
          jr_leave_rate: parsePercent(leave_rate),
          merits_total: Number(merits_total),
          leave_total: Number(leave_total),
          jr_total: jr_granted + pending_jr,
          year: Number(year),
        })
      );

      setPerYearEntries(formattedYears);
      setAvailableYears([...formattedYears.map(({ year }) => year!).sort()]);
    }
  }, [perYearData]);

  useEffect(() => {
    if (yearSummaryData) {
      const {
        jr_granted,
        leave_rate,
        leave_total,
        merits_rate,
        merits_total,
        overall_rate,
        pending_jr,
        predicted_leave_rate,
        predicted_merits_rate,
        predicted_overall_rate,
      }: FCJudgeQueryResponseV2 = yearSummaryData[0];

      setYearSummary({
        jr_leave_rate: parsePercent(leave_rate),
        overall_jr_rate: parsePercent(overall_rate),
        merits_rate: parsePercent(merits_rate),
        merits_total: Number(merits_total),
        leave_total: Number(leave_total),
        jr_total: jr_granted + pending_jr,
        predicted_leave_rate: parsePercent(predicted_leave_rate),
        predicted_merits_rate: parsePercent(predicted_merits_rate),
        predicted_overall_rate: parsePercent(predicted_overall_rate),
      });
    }
  }, [yearSummaryData]);

  useEffect(() => {
    if (fcYearSummaryData) {
      const {
        jr_granted,
        leave_rate,
        leave_total,
        merits_rate,
        merits_total,
        overall_rate,
        pending_jr,
      }: FCQueryV2Response = fcYearSummaryData;

      setFCYearSummary({
        overall_jr_rate: parsePercent(overall_rate),
        merits_rate: parsePercent(merits_rate),
        jr_leave_rate: parsePercent(leave_rate),
        merits_total: Number(merits_total || 0),
        leave_total: Number(leave_total || 0),
        jr_total: jr_granted + pending_jr || 0,
      });
    }
  }, [fcYearSummaryData]);

  useEffect(() => {
    if (judgesData) {
      const parsedGraphData: JudgeEntry[] = judgesData.map(
        ({
          leave_total,
          merits_rate,
          merits_total,
          leave_rate,
          jr_granted,
          judge,
          pending_jr,
          overall_rate,
        }: FCJudgeQueryResponseV2) => ({
          jr_rate: parsePercent(overall_rate),
          jr_total: jr_granted + pending_jr,
          leave_rate: parsePercent(leave_rate),
          leave_total,
          merits_rate: parsePercent(merits_rate),
          merits_total,
          id: judge,
          name:
            judgesInfo.find((info) => info.id === judge)?.displayName || judge,
        })
      );

      setGraphData(parsedGraphData);
    }
  }, [judgesData]);

  // Loading
  useEffect(() => {
    setLoading(
      perYearLoading ||
        yearSummaryDataLoading ||
        judgesLoading ||
        fcYearSummaryDataLoading
    );
  }, [
    perYearLoading,
    yearSummaryDataLoading,
    judgesLoading,
    fcYearSummaryDataLoading,
  ]);

  return (
    <Layer>
      <SectionLabelsContainer>
        <Detail>
          {info.displayName}
          <br />
          <span style={{ color: "var(--dark-2)" }}>Federal Court Justice</span>
        </Detail>
        <SectionIndicator start="biography" />
        <SectionLabel
          href="#biography"
          indented
          label="Personal information & biography"
        />
        <SectionLabel href="#key-data" indented label="Key Data" />
        <SubSections>
          <Detail>JR Leave Rate</Detail>
          <Detail>JR Merits Rate</Detail>
          <Detail>Number of JR Leave Decisions</Detail>
          <Detail>Number of JR Merits Decisions</Detail>
        </SubSections>
        <SectionLabel href={`#judge-data`} indented label={"Judge Data"} />
        <SubSections>
          <Detail>JR Leave Rate per Overall JR Rate (leave judge)</Detail>
        </SubSections>
        <SectionLabel href={`#yearly-data`} indented label={"Yearly Data"} />
        <SubSections>
          <Detail>Rates per year</Detail>
          {/* <Detail>Number of decisions per year</Detail> */}
        </SubSections>
      </SectionLabelsContainer>
      <Container>
        <Section id="biography">
          <Display>{info.displayName}</Display>
          <h2 style={{ marginTop: 0 }}>Federal Court Justice</h2>
          {imageURL && info.bio && info.sourceURL && (
            <JudgeBio
              source={info.sourceURL}
              imageURL={imageURL}
              bio={info.bio}
            />
          )}
        </Section>
        <hr />
        {(yearSummary.leave_total < 30 || yearSummary.merits_total < 30) && (
          <BannerContainer>
            <Banner style={{ width: "60%" }}>
              <BannerContent>
                <Alert style={{ marginBottom: "8px" }}>
                  The data available is limited.
                </Alert>
                <Subtitle>
                  {makeBannerMessage(
                    yearSummary.leave_total,
                    yearSummary.merits_total
                  )}
                </Subtitle>
              </BannerContent>
            </Banner>
          </BannerContainer>
        )}
        <div style={{ position: "relative" }}>
          {showDataFilter && (
            <PageFilterBox>
              <Detail style={{ marginRight: "12px" }}>Filter by year</Detail>
              <Select
                size="small"
                onChange={(e) => setYearPageFilter(e.target.value)}
                value={yearPageFilter}
              >
                <MenuItem value={ALL_INDICATOR}>
                  {earliestYear === latestYear ? (
                    <Action>{`All (${latestYear})`}</Action>
                  ) : (
                    <Action>{`All ${earliestYear} to ${latestYear}`}</Action>
                  )}
                </MenuItem>
                {[...availableYears].reverse().map((year) => (
                  <MenuItem key={`y-${year}`} value={year}>
                    <Action>{year}</Action>
                  </MenuItem>
                ))}
              </Select>
            </PageFilterBox>
          )}
          <Section id="key-data">
            <Display>Key Data</Display>
            <Title>
              from{" "}
              {yearPageFilter === ALL_INDICATOR &&
              earliestYear !== latestYear ? (
                <>
                  <b>{earliestYear}</b> to <b>{latestYear}</b>
                </>
              ) : (
                <b>
                  {earliestYear === latestYear ? latestYear : yearPageFilter}
                </b>
              )}
            </Title>
            <KeyStatsContainer>
              <TopStatsContainer>
                <PercentStatBox style={{ backgroundColor: "#AC346D" }}>
                  <StatTitleContainer>
                    <h2>Refugee JR Leave Rate</h2>
                  </StatTitleContainer>
                  <StatAvg
                    stat={yearSummary.jr_leave_rate}
                    avg={yearSummary.predicted_leave_rate!}
                  />
                </PercentStatBox>
                <PercentStatBox style={{ backgroundColor: "#2D7299" }}>
                  <StatTitleContainer>
                    <h2>Refugee JR Merits Rate</h2>
                    <Subtitle>Leave granted</Subtitle>
                  </StatTitleContainer>
                  <StatAvg
                    stat={yearSummary.merits_rate}
                    avg={yearSummary.predicted_merits_rate!}
                  />
                </PercentStatBox>
              </TopStatsContainer>
              <BottomStatsContainer>
                <CountStatBox style={{ backgroundColor: "#4F4F4F" }}>
                  <StatTitleContainer>
                    <h2>Number of Refugee JR Leave Decisions</h2>
                  </StatTitleContainer>
                  <Stat>
                    <CountUp
                      preserveValue
                      end={yearSummary.leave_total}
                      formattingFn={(n) => n.toLocaleString()}
                      duration={0.25}
                    />
                  </Stat>
                </CountStatBox>
                <CountStatBox style={{ backgroundColor: "#494A17" }}>
                  <StatTitleContainer>
                    <h2>Number of Refugee JR Merits Decisions</h2>
                  </StatTitleContainer>
                  <Stat>
                    <CountUp
                      preserveValue
                      end={yearSummary.merits_total}
                      formattingFn={(n) => n.toLocaleString()}
                      duration={0.25}
                    />
                  </Stat>
                </CountStatBox>
              </BottomStatsContainer>
            </KeyStatsContainer>
            <Actions>
              <Subtitle>*predicted based on yearly judge average</Subtitle>
            </Actions>
          </Section>
          <Section id="judge-data">
            <Display>Judge Data</Display>
            <Title>
              from{" "}
              {yearPageFilter === ALL_INDICATOR &&
              earliestYear !== latestYear ? (
                <>
                  <b>{earliestYear}</b> to <b>{latestYear}</b>
                </>
              ) : (
                <b>
                  {earliestYear === latestYear ? latestYear : yearPageFilter}
                </b>
              )}
            </Title>
            <div>
              <h2>Refugee JR Leave Rates per Judge</h2>
              <FCLeaveRateHorizontalGraph
                judges={graphData}
                average={fcYearSummary.jr_leave_rate}
                judgeName={info.id!}
                judgeRate={yearSummary.jr_leave_rate}
              />
            </div>
            <div>
              <h2>Refugee JR Merits Rates per Judge</h2>
              <FCMeritsHorizontalGraph
                judges={graphData}
                average={fcYearSummary.merits_rate}
                judgeName={info.id!}
                judgeRate={yearSummary.merits_rate}
              />
            </div>
            <div>
              <h2>JR Leave Rate per Overall JR Rate (leave judge)</h2>
              <RefugeeJRLeaveRatePerOverallRefugeeJRRateLeaveJudge
                showAverage
                judgeName={info.displayName}
                data={[
                  ...graphData.map(({ id, jr_rate, leave_rate, name }) => ({
                    id,
                    jr_rate,
                    leave_rate,
                    name,
                  })),
                  {
                    judge_overall_jr_rate: yearSummary.overall_jr_rate,
                    leave_rate: yearSummary.jr_leave_rate,
                  },
                  {
                    leave_rate: fcYearSummary.jr_leave_rate,
                    average: fcYearSummary.overall_jr_rate,
                  },
                ]}
              />
            </div>
            <div>
              <h2>JR Leave Rate per Merits Rate</h2>
              <FCLeaveRatePerMeritsRate
                showAverage
                judgeName={info.displayName}
                data={[
                  ...graphData.map(({ id, merits_rate, leave_rate, name }) => ({
                    id,
                    merits_rate,
                    leave_rate,
                    name,
                  })),
                  {
                    judge_merits_rate: yearSummary.merits_rate,
                    leave_rate: yearSummary.jr_leave_rate,
                  },
                  {
                    leave_rate: fcYearSummary.jr_leave_rate,
                    average: fcYearSummary.merits_rate,
                  },
                ]}
              />
            </div>
          </Section>
          <Section id="yearly-data">
            <Display>Yearly Data</Display>
            <Title>
              from{" "}
              {earliestYear === latestYear ? (
                <b>{latestYear}</b>
              ) : (
                <b>
                  {earliestYear} to {latestYear}
                </b>
              )}
            </Title>
            <div>
              <h2>Rates per year</h2>
              <RefugeeJRLeaveRatePerYear
                selectedYear={yearPageFilter}
                data={perYearEntries.map(
                  ({ jr_leave_rate, merits_rate, year }) => ({
                    jr_leave_rate,
                    merits_rate,
                    year: year!,
                  })
                )}
              />
            </div>
          </Section>
        </div>
        {/* <Section id="recent-cases" style={{ width: "60%" }}>
          <h2>Most recent cases</h2>
          <Cases cases={cases} />
          <Actions style={{ justifyContent: "center" }}>
            <Button variant="contained">
              <Action>load more</Action>
            </Button>
          </Actions>
        </Section> */}
        {/* <Section id="most-frequently-cited" style={{ width: "60%" }}>
          <h2>Judge’s cases that are most frequently cited</h2>
          <Cases cases={cases} />
          <Actions style={{ justifyContent: "center" }}>
            <Button variant="contained">
              <Action>load more</Action>
            </Button>
          </Actions>
        </Section> */}
        {/* <Section id="cites-most-frequently" style={{ width: "60%" }}>
          <h2>Cases that the judge cites most frequently</h2>
          <Cases cases={cases} />
          <Actions style={{ justifyContent: "center" }}>
            <Button variant="contained">
              <Action>load more</Action>
            </Button>
          </Actions>
        </Section> */}
        {/* <hr /> */}
        {/* <Section id="canlii-cases">
          <CaseSearch />
        </Section> */}
        {/* <hr /> */}
        {/* <Section
          style={pinnedCommunity ? pinnedCommunityStyle : {}}
          id="community"
        >
          <Community
            onPin={pinCommunity}
            pinned={pinnedCommunity}
            data={FCSampleCommunity}
            description="Comments and tips related to this decision maker"
          />
        </Section> */}
      </Container>
    </Layer>
  );
}

export default FCJudgeV2;
