import { createGlobalStyle } from "styled-components";
import { createTheme } from "@mui/material/styles";

export const globalTheme = createTheme({
  palette: {
    primary: {
      main: "#0B0B0B",
    },
    secondary: {
      main: "#FAFAFA",
    },
  },
});

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  body {
    // colors design -> https://www.figma.com/file/U9KQdLIeOnatNKlyCavj79/Colors
    --light-1: #FAFAFA;
    --dark-1: #0B0B0B;
    --dark-2: #626262BF;
    --grey-1: rgba(0, 0, 0, 0.15);
    --dark-grey: #626262DB;
    --pink: #E26EA5;
    --yellow: #F5C416;
    --green: #B3D335;
    --blue: #29C3EC;
    --blue-link: #1578B0;

    --footer-height: 284px;
    --nav-height: 52px;

    --shadow-basic: 0px 0px 12px rgba(0, 0, 0, 0.25);

    background-color: var(--light-1);
    min-height: 100%;
    margin: 0;

    @media (max-width: 600px) {
      --footer-height: 384px;
    }
  }

  html {
    font-size: 100%;
    scroll-behavior: smooth;
  }

  // typograhpy design -> https://www.figma.com/file/bTGFFZqlpUz3ragnXSa1XO/Typography
  h1, h2, h3 {
    color: var(--dark-1);
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
  }

  p, label {
    color: var(--dark-1);
    font-family: EB Garamond;
    font-style: normal;
    font-weight: normal;
  }

  a, a:visited {
    color: var(--blue-link);
  }

  button p {
    text-transform: none;
  }

  hr {
    border: none;
    height: 0.5px;
    background-color: var(--grey-1);
    outline: none;
    width: 100%;
  }

  .MuiButton-containedPrimary {
    p {
      color: var(--light-1);
    }
  }

  .MuiButton-containedSecondary {
    p {
      color: var(--dark-1);
    }
  }

  /* * {
    border: solid 1px orange;
  } */
`;
