import styled from "styled-components";
import { IRBCountry } from "../util/interfaces";
import { Alert, CountryLine, Detail, Display, Subtitle } from "../shared/Common";

const CountryContainer = styled.div`
  padding: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;

  p,
  b,
  label,
  h1,
  h2 {
    color: var(--light-1);
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: appear 250ms ease;

  position: relative;
  transition: box-shadow 250ms ease;

  @media (min-width: 600px) {
    :hover {
      box-shadow: var(--shadow-basic);

      .line-left {
        transform: translateX(-16px);
        opacity: 1;
      }
    }
  }
`;

const Left = styled.div`
  display: grid;
  grid-row-gap: 32px;
`;

const Info = styled.div`
  display: grid;
  grid-row-gap: 16px;

  h2 {
    margin: 0;
  }
`;

const ClaimsBox = styled.div`
  p {
    margin: 0;
  }
`;

const Percentages = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: max-content 1px 96px;
  justify-self: flex-end;

  @media (max-width: 700px) {
    grid-template-columns: none;
    grid-template-rows: 1fr 1px 1fr;
  }
`;

const PercentBox = styled.div`
  display: grid;
  grid-row-gap: 8px;
  align-content: flex-end;

  h1,
  h2 {
    margin: 0;
  }

  h1,
  h2,
  p {
    justify-self: flex-end;
  }
`;

function CountryCard({ country, rank }: { country: IRBCountry; rank: number }) {
  const { name, decisions, predicted, rate } = country;

  return (
    <CountryContainer
      style={{
        backgroundColor: rank % 2 === 0 ? "#414141" : "var(--dark-1)",
      }}
    >
      <Left>
        <b>{rank}</b>
        <Info>
          <h2>{name}</h2>
          <ClaimsBox>
            <Alert>~{decisions}</Alert>
            <Detail>claims</Detail>
          </ClaimsBox>
        </Info>
      </Left>
      <Percentages>
        <PercentBox>
          <Display>{`${rate}%`}</Display>
          <Subtitle>recognition rate</Subtitle>
        </PercentBox>
        <hr style={{ borderColor: "var(--dark-2)" }} />
        <PercentBox>
          <h2 style={{ color: "var(--yellow)" }}>{`${predicted}%`}</h2>
          <Subtitle>predicted</Subtitle>
        </PercentBox>
      </Percentages>
      <CountryLine className="line-left" />
    </CountryContainer>
  );
}

export default CountryCard;
