import { Button, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ALL_INDICATOR } from "../util/constants";
import { IRBCountry } from "../util/interfaces";
import { Action, Actions, CountriesContainer, EmptyResults } from "../shared/Common";
import CountryCard from "./CountryCard";

const COUNTRIES_PAGE_OFFSET = 5;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

function MemberCountries({
  countries,
  countryFilter,
  setCountryFilter,
}: {
  countries: IRBCountry[];
  countryFilter: string;
  setCountryFilter: (country: string) => void;
}) {
  const [page, setPage] = useState(1);
  const [pageCountries, setPageCountries] = useState<IRBCountry[]>([]);

  const selectedCountry = countries.find(({ name }) => name === countryFilter);

  useEffect(() => {
    const actualPage = page - 1;
    setPageCountries(
      countries.slice(
        actualPage * COUNTRIES_PAGE_OFFSET,
        actualPage * COUNTRIES_PAGE_OFFSET + COUNTRIES_PAGE_OFFSET
      )
    );
  }, [page, countries]);

  useEffect(() => {
    setPage(1);
  }, [countries]);

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Content>
      {countries.length === 0 ? (
        <EmptyResults message="No countries found" />
      ) : (
        <CountriesContainer>
          {countryFilter === ALL_INDICATOR
            ? pageCountries.map((country, index) => (
                <CountryCard
                  key={`country-${index}`}
                  country={country}
                  rank={COUNTRIES_PAGE_OFFSET * (page - 1) + index + 1}
                />
              ))
            : selectedCountry !== undefined && (
                <CountryCard country={selectedCountry} rank={1} />
              )}
        </CountriesContainer>
      )}
      {countryFilter !== ALL_INDICATOR && (
        <Actions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={() => setCountryFilter(ALL_INDICATOR)}
          >
            <Action>
              Reset country filter to <b>all countries</b>
            </Action>
          </Button>
        </Actions>
      )}
      <Actions style={{ marginTop: "32px", justifyContent: "center" }}>
        <Pagination
          onChange={handlePageChange}
          page={page}
          count={Math.ceil(countries.length / COUNTRIES_PAGE_OFFSET)}
          color="primary"
        />
      </Actions>
    </Content>
  );
}

export default MemberCountries;
