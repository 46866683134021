import { globalTheme, GlobalStyles } from "./global.styles";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CheckCircle, Warning } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import FederalCourt from "./components/FederalCourt";
import styled from "styled-components";
import IRBMember from "./components/Immigration Refugee Board/IRBMember";
import ImmigrationRefugeeBoard from "./components/Immigration Refugee Board/ImmigrationRefugeeBoard";
import Glossary from "./components/Glossary";
import FCJudgeV2 from "./components/FCJudgeV2";
import TermsOfUse from "./components/Legal/TermsofUse";
import {
  Action,
  DesktopOnly,
  Detail,
  LoadingOverlay,
  MobileOnly,
} from "./shared/Common";

// import { duckDb, getYearly } from "./services/db";
import * as duckDb from "./services/db";
import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { useEffect, useState } from "react";

const Layer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const DevelopmentBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px;
  background-color: var(--yellow);
  z-index: 100;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  margin-bottom: 32px;

  // min height vertical view - footer height - content margin
  min-height: calc(100vh - var(--footer-height) - 30px);
`;

function App() {
  const [dbInitialized, setDbInitialized] = useState(false);
  const [duckDbConn, setDuckDbConn] = useState<AsyncDuckDBConnection | null>(
    null
  );
  const [developmentBanner, setDevelopmentBanner] = useState(true);

  useEffect(() => {
    duckDb.initializeDB().then((conn) => {
      setDbInitialized(true);
      setDuckDbConn(conn);
    });
  }, []);

  return (
    <>
      {!dbInitialized && <LoadingOverlay />}
      {duckDbConn && (
        <ThemeProvider theme={globalTheme}>
          <GlobalStyles />
          <Router>
            <Layer>
              <Content>
                <Switch>
                  <Route path="/federal-court/judge/:id">
                    <FCJudgeV2 duckDbConn={duckDbConn} />
                  </Route>
                  <Route path="/federal-court">
                    <FederalCourt duckDbConn={duckDbConn} />
                  </Route>
                  <Route path="/immigration-refugee-board/member/:id">
                    <IRBMember duckDbConn={duckDbConn} />
                  </Route>
                  <Route path="/immigration-refugee-board">
                    <ImmigrationRefugeeBoard duckDbConn={duckDbConn} />
                  </Route>
                  <Route path="/glossary">
                    <Glossary />
                  </Route>
                  <Route path="/terms-of-use">
                    <TermsOfUse />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              </Content>
              <Footer />
              <Nav />
              {developmentBanner && (
                <DevelopmentBanner>
                  <Warning />
                  <Detail style={{ marginLeft: "8px" }}>
                    This website is under development. Charts and figures are
                    for demonstration purposes only, using data that may be
                    outdated or inaccurate.
                  </Detail>
                  <MobileOnly>
                    <IconButton
                      style={{ marginLeft: "12px" }}
                      onClick={() => setDevelopmentBanner(false)}
                    >
                      <CheckCircle fontSize="medium" color="primary" />
                    </IconButton>
                  </MobileOnly>
                  <DesktopOnly>
                    <Button
                      style={{ marginLeft: "12px" }}
                      variant="outlined"
                      onClick={() => setDevelopmentBanner(false)}
                    >
                      <Action>I understand</Action>
                    </Button>
                  </DesktopOnly>
                </DevelopmentBanner>
              )}
            </Layer>
          </Router>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
