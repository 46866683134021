import { Button, Container } from "@mui/material";
import { useHistory } from "react-router";
import styled from "styled-components";
import logoDark from "../assets/icon-dark-color.svg";
import {
  Action,
  Actions,
  Detail,
  Display,
  Subtitle,
  Title,
} from "../shared/Common";

const Layer = styled.div`
  margin-bottom: -32px;
  display: flex;
  background: linear-gradient(
    45deg,
    rgba(245, 196, 22, 0.9) 0%,
    rgba(226, 110, 165, 0.9) 50%,
    rgba(41, 195, 236, 0.9) 100%
  );
  background-size: 400% 400%;

  @keyframes wave {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  animation: wave 20s ease infinite;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Landing = styled.div`
  display: flex;
  height: calc(100vh - 45px);
`;

const Box = styled.div`
  display: flex;
  height: 75%;
  width: clamp(90%, 600px, 1440px);
  outline: solid 2px var(--dark-1);
  position: relative;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 48px;
  max-width: 70%;
  background-color: var(--dark-1);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  z-index: 4;
  position: absolute;
  right: -48px;
  bottom: -48px;

  h1,
  p {
    color: var(--light-1);
  }

  h1 {
    margin-top: 0;
    margin-bottom: 24px;
  }

  @media (max-width: 700px) {
    padding: 24px;
    right: 0px;
    bottom: 0px;
    max-width: 100%;
  }
`;

const Logo = styled.img`
  top: 48px;
  left: -64px;
  position: absolute;
  object-fit: contain;
  aspect-ratio: 1;
  height: 360px;
  width: 360px;
  z-index: 4;

  @media (max-width: 700px) {
    top: -40px;
    left: calc(50% - 66px);
    height: 132px;
    width: 132px;
  }
`;

function Home() {
  const history = useHistory();

  return (
    <Layer>
      <Content>
        <Landing>
          <Container
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Logo src={logoDark} />
              <Message>
                <Display>Welcome to the Refugee Law Lab Portal</Display>
                <Title>
                  This website presents legal analytics about Canadian refugee
                  decisions using data compiled from the Federal Court and the
                  Immigration and Refugee Board.
                </Title>
                <Actions
                  style={{
                    marginTop: "32px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push("/immigration-refugee-board")}
                  >
                    <Action style={{ color: "var(--dark-1)" }}>
                      Immigration and Refugee Board
                    </Action>
                  </Button>
                  <Button
                    style={{
                      marginLeft: "8px",
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push("/federal-court")}
                  >
                    <Action style={{ color: "var(--dark-1)" }}>
                      Federal Court
                    </Action>
                  </Button>
                </Actions>
              </Message>
            </Box>
          </Container>
        </Landing>
      </Content>
    </Layer>
  );
}

export default Home;
