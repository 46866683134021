import {
  FCJudgePerYearQueryResponse,
  FCPerYearQueryResponse,
  FCYearEntry,
  FCYearlySummaryQueryResponse,
  Judge,
  JudgeEntry,
  JudgeQueryResponseEntry,
  JudgeSortOption,
} from "../interfaces";
import fcBios from "../../mock/fc_bios.json";
import { parsePercent } from "../helpers";
// import { FC_AVAILABLE_YEARS } from "../constants";

export const getJudges = () => {
  const judges: Judge[] = [];

  for (let index in Object.keys(fcBios.Bio)) {
    const entry = index as keyof typeof fcBios.Bio;

    const newJudge: Judge = {
      bio: fcBios.Bio[entry],
      displayName: fcBios.DisplayName[entry],
      id: fcBios.DocketParsedNameOutput[entry],
      imageUrl: fcBios.File[entry],
      JRLeaveDecisionsCount: 100,
      recentDate: "2019",
      JRMeritDecisionsCount: 100,
      name: fcBios.DisplayName[entry].slice(14),
      sourceURL: fcBios.Source[entry],
      docketParsedNameOutput: fcBios.DocketParsedNameOutput[entry],
    };

    judges.push(newJudge);
  }

  return judges;
};

export const getJudge = (targetId: string): Judge => {
  const judges = getJudges();
  return judges.find(({ id }) => targetId === id)!;
};

export const processYearlyFCData = (
  response: FCYearlySummaryQueryResponse
): FCYearEntry => {
  const { avg, sum } = response.fc_judges_yearly_aggregate.aggregate;

  return {
    overall_jr_rate: parsePercent(avg.jr_rate),
    merits_rate: parsePercent(avg.merits_rate),
    jr_leave_rate: parsePercent(avg.leave_rate),
    merits_total: sum.merits_total || 0,
    leave_total: sum.leave_total || 0,
    jr_total: sum.jr_total || 0,
  };
};

export const processJudgeDataToJudgeList = (
  response: JudgeQueryResponseEntry
): JudgeEntry[] => {
  const entries = response.fc_judges_yearly_aggregate.nodes;

  const judgeNames = getUniqueJudgeNames(entries);
  const judgeData: any = {};
  const result: JudgeEntry[] = [];

  judgeNames.forEach((name) => {
    judgeData[name] = {
      jr_rate: 0,
      jr_total: 0,
      jr_count: 0,
      leave_rate: 0,
      leave_total: 0,
      leave_count: 0,
      merits_rate: 0,
      merits_total: 0,
      merits_count: 0,
    };
  });

  for (let {
    name,
    jr_rate,
    jr_total,
    merits_rate,
    merits_total,
    leave_rate,
    leave_total,
  } of entries) {
    if (jr_rate && jr_total) {
      judgeData[name].jr_rate += jr_rate;
      judgeData[name].jr_total += jr_total;
      judgeData[name].jr_count += 1;
    }

    if (merits_rate && merits_total) {
      judgeData[name].merits_rate += merits_rate;
      judgeData[name].merits_total += merits_total;
      judgeData[name].merits_count += 1;
    }

    if (leave_rate && leave_total) {
      judgeData[name].leave_rate += leave_rate;
      judgeData[name].leave_total += leave_total;
      judgeData[name].leave_count += 1;
    }
  }

  judgeNames.forEach((name) => {
    const judgeEntry = judgeData[name];

    result.push({
      id: name.trim(),
      name,
      jr_rate: parsePercent(judgeEntry.jr_rate / judgeEntry.jr_count),
      jr_total: judgeEntry.jr_total,
      merits_rate: parsePercent(
        judgeEntry.merits_rate / judgeEntry.merits_count
      ),
      merits_total: judgeEntry.merits_total,
      leave_rate: parsePercent(judgeEntry.leave_rate / judgeEntry.leave_count),
      leave_total: judgeEntry.leave_total,
    });
  });

  return result;
};

export const getUniqueJudgeNames = (
  data: {
    name: string;
  }[]
): Set<string> => {
  const names = new Set<string>();

  for (let { name } of data) {
    names.add(name);
  }

  return names;
};

export const sortJudges = (
  judges: JudgeEntry[],
  sortOption: JudgeSortOption
) => {
  let sortedJudges: JudgeEntry[] = [...judges];

  switch (sortOption) {
    case "name":
      sortedJudges.sort((a, b) => a.name.localeCompare(b.name));
      break;
    case "jr_rate":
      sortedJudges.sort((a, b) => (b.jr_rate || 0) - (a.jr_rate || 0));
      break;
    case "jr_total":
      sortedJudges.sort((a, b) => (b.jr_total || 0) - (a.jr_total || 0));
      break;
    case "merits_rate":
      sortedJudges.sort((a, b) => (b.merits_rate || 0) - (a.merits_rate || 0));
      break;
    case "merits_total":
      sortedJudges.sort(
        (a, b) => (b.merits_total || 0) - (a.merits_total || 0)
      );
      break;
    case "leave_rate":
      sortedJudges.sort((a, b) => (b.leave_rate || 0) - (a.leave_rate || 0));
      break;
    case "leave_total":
      sortedJudges.sort((a, b) => (b.leave_total || 0) - (a.leave_total || 0));
      break;
  }

  return sortedJudges;
};

export const processPerYearDataEntries = (
  response: FCPerYearQueryResponse
): FCYearEntry[] => {
  const { fc_yearly_leave, fc_yearly_merit } = response;

  interface Years {
    [key: string]: {
      leave_total: number;
      leave_rate: number;
      merits_total: number;
      merits_rate: number;
      year: number;
    };
  }

  const yearsData: Years = {};
  const responseYears = fc_yearly_leave
    .map(({ year }) => year.toString())
    .sort();

  for (let year of responseYears) {
    yearsData[year] = {
      leave_total: 0,
      leave_rate: 0,
      merits_total: 0,
      merits_rate: 0,
      year: parseInt(year),
    };
  }

  for (let { year, leave_total, leave_granted } of fc_yearly_leave) {
    if (year in yearsData) {
      yearsData[year as keyof typeof yearsData].leave_total = leave_total;
      yearsData[year as keyof typeof yearsData].leave_rate = parsePercent(
        leave_granted / leave_total
      );
    }
  }

  for (let { year, merits_total, merits_granted } of fc_yearly_merit) {
    yearsData[year as keyof typeof yearsData].merits_total = merits_total;
    yearsData[year as keyof typeof yearsData].merits_rate = parsePercent(
      merits_granted / merits_total
    );
  }

  const result: FCYearEntry[] = [];

  Object.values(yearsData).forEach(
    ({ merits_rate, merits_total, leave_rate, leave_total, year }) => {
      result.push({
        merits_rate,
        jr_leave_rate: leave_rate,
        case_count: merits_total + leave_total,
        year,
        overall_jr_rate: 0,
        jr_total: 0,
        leave_total: 0,
        merits_total: 0,
      });
    }
  );

  return result;
};

export const processJudgePerYearDataEntries = (
  response: FCJudgePerYearQueryResponse
): FCYearEntry[] =>
  response.fc_judges_yearly_aggregate.nodes.map(
    ({ year, leave_rate, jr_rate }) => ({
      year: year!,
      jr_leave_rate: parsePercent(leave_rate),
      overall_jr_rate: parsePercent(jr_rate),
      merits_rate: 0,
      jr_total: 0,
      leave_total: 0,
      merits_total: 0,
      case_count: 0,
    })
  );
