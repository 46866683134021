import { Button } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { Action, Actions } from "../../shared/Common";
import { OpenInNew } from "@mui/icons-material";

const BIO_HEIGHT = 580;
const MOBILE_HEIGHT = 440;

const Box = styled.div`
  position: relative;
  min-height: ${BIO_HEIGHT}px;

  @media (max-width: 700px) {
    min-height: ${MOBILE_HEIGHT}px;
  }
`;

const Portrait = styled.img`
  height: ${BIO_HEIGHT}px;
  width: 100%;
  object-fit: cover;

  @media (max-width: 700px) {
    height: ${MOBILE_HEIGHT}px;
  }
`;

const BioBox = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 105%;
  max-height: 100%;
  align-items: flex-end;
  background-color: var(--light-1);
  box-shadow: var(--shadow-basic);
  transform: translateX(-2.5%);
  border-radius: 8px;

  button {
    p {
      white-space: nowrap;
    }
  }
`;

const Bio = styled.div`
  display: flex;
  width: 100%;
  max: 40px;
  overflow: hidden;
  position: relative;

  p {
    margin: 0;
  }

  transition: max-height 250ms ease;
`;

function JudgeBio({
  bio,
  imageURL,
  source,
}: {
  bio: string;
  imageURL: string;
  source: string;
}) {
  const [fullBio, setFullBio] = useState(false);

  return (
    <Box>
      <Portrait src={imageURL} />
      <BioBox>
        <Bio
          style={{
            maxHeight: fullBio ? `${BIO_HEIGHT}px` : "40px",
            overflow: fullBio ? "scroll" : "hidden",
          }}
        >
          <p>{bio}</p>
        </Bio>
        <Actions style={{ marginTop: "16px" }}>
          <Button
            style={{ marginRight: "8px" }}
            onClick={() => setFullBio(!fullBio)}
          >
            <Action>{fullBio ? "Hide" : "Show"} full biography</Action>
          </Button>
          <a href={source} target="_blank" rel="noreferrer">
            <Button>
              <Action>source</Action>
              <OpenInNew style={{ marginLeft: "4px" }} />
            </Button>
          </a>
        </Actions>
      </BioBox>
    </Box>
  );
}

export default JudgeBio;
