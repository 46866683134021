import { Container } from "@mui/material";
import styled from "styled-components";
import { Display, Section, Title, Subtitle } from "../../shared/Common";

const Layer = styled.div`
  display: flex;
`;

const Point = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;

  p {
    &:nth-child(2) {
      margin-bottom: 0px;
      margin-top: 0px;
      padding-left: 16px;
      width: 100%;
    }

    &:nth-child(1) {
      width: 40px;
      text-align: end;
    }
  }
`;

function TermsOfUse() {
  return (
    <Layer>
      <Container>
        <Section>
          <Display>Terms of Use</Display>
        </Section>
        <hr />
        <Section>
          <Title>Agreement</Title>
          <Point>
            <Subtitle>1.</Subtitle>
            <p>
              By using this website, including by accessing content
              programmatically, you agree to these terms of use.
            </p>
          </Point>
          <Point>
            <Subtitle>2.</Subtitle>
            <p>These terms of use may be amended at any time without notice.</p>
          </Point>
          <Point>
            <Subtitle>3.</Subtitle>
            <p>
              If you do not agree to these terms, you must stop using the site
              immediately and delete any data obtained from the site.
            </p>
          </Point>
        </Section>
        <Section>
          <Title>Ownership</Title>
          <Point>
            <Subtitle>4.</Subtitle>
            <p>
              This website is owned and maintained by Professor Sean Rehaag in
              his capacity as a faculty member at York University's Osgoode Hall
              Law School as part of his ongoing academic research.
            </p>
          </Point>
          <Point>
            <Subtitle>5.</Subtitle>
            <p>
              All intellectual property rights to the information on the website
              are reserved unless otherwise expressly indicated.
            </p>
          </Point>
        </Section>
        <Section>
          <Title>Content</Title>
          <Point>
            <Subtitle>6.</Subtitle>
            <p>
              Content on this site is provided for informational
              purposes. Nothing on this website entails legal advice.
            </p>
          </Point>
          <Point>
            <Subtitle>7.</Subtitle>
            <p>
              Data and statistics on this site were obtained through academic
              research projects, including projects that use automated
              processes. While we try to make the data and statistics as
              accurate as possible, our methodologies may result in inaccurate
              or outdated data and statistics. As such, data and statistics on
              this site should be viewed as preliminary information aimed to
              prompt further research and discussion, rather than as definitive
              information.
            </p>
          </Point>
        </Section>
        <Section>
          <Title>Use</Title>
          <Point>
            <Subtitle>8.</Subtitle>
            <p>
              You must not use this website for any purpose other than your own
              personal non-commercial use unless you obtain advance written
              permission from the website owner.
            </p>
          </Point>
        </Section>
        <Section>
          <Title>Reproduction</Title>
          <Point>
            <Subtitle>9.</Subtitle>
            <p>
              You may reproduce statistics and data about refugee
              decision-making made available on this website, but only for
              non-commercial purposes with attribution, through a{" "}
              <a href="https://creativecommons.org/licenses/by-nc/4.0/legalcode">
                Creative Commons Attribution-NonCommercial 4.0 International
              </a>{" "}
              license.
            </p>
          </Point>
        </Section>
        <Section>
          <Title>Disclaimer</Title>
          <Point>
            <Subtitle>10.</Subtitle>
            <p>
              You use this website at your own risk, without any guarantees or
              promises. There are no express or implied warranties of any kind,
              including that the site will work, be safe, or be free of viruses.
              There are also no warranties that the information on the site is
              accurate or complete.
            </p>
          </Point>
        </Section>
        <Section>
          <Title>Liability</Title>
          <Point>
            <Subtitle>11.</Subtitle>
            <p>
              York University, Osgoode Hall Law School, the Refugee Law
              Laboratory, Professor Sean Rehaag (and all employees, students and
              volunteers working with them) are not liable for any direct or
              indirect damages resulting from your use of this website, of any
              data obtained directly or indirectly from this website, or from
              the non-availability of this website.
            </p>
          </Point>
        </Section>
        <Section>
          <Title>Indemnification</Title>
          <Point>
            <Subtitle>12.</Subtitle>
            <p>
              You shall indemnify York University, Osgoode Hall Law School, the
              Refugee Law Laboratory, Professor Sean Rehaag (and all employees,
              students and volunteers working with them) from any liabilities or
              expenses arising from your use of this website or of any data
              obtained directly or indirectly from this website.
            </p>
          </Point>
        </Section>
        <Section>
          <Title>Governing Law</Title>
          <Point>
            <Subtitle>13.</Subtitle>
            <p>
              Use of this website and any data obtained directly or indirectly
              from this website is governed exclusively through the federal law
              of Canada and provincial law of Ontario. Ontario courts and
              Federal Canadian courts have exclusive jurisdiction.
            </p>
          </Point>
        </Section>
        <hr />
      </Container>
    </Layer>
  );
}

export default TermsOfUse;
