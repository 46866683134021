import { Container } from "@mui/material";
import styled from "styled-components";
import {
  Detail,
  Display,
  Section,
  SectionLabel,
  SectionLabelsContainer,
  Title,
} from "../shared/Common";
import SectionIndicator from "./SectionIndicator";

const Layer = styled.div`
  display: flex;

  h2 {
    margin: 0;
  }

  p {
    margin-bottom: 0;
  }
`;

function Glossary() {
  return (
    <Layer>
      <SectionLabelsContainer>
        <Detail>Glossary</Detail>
        <SectionIndicator start="active-members" />
        <SectionLabel href="#active-members" indented label="Active Members" />
        <SectionLabel href="#country" indented label="Country" />
        <SectionLabel href="#decisions" indented label="Decisions" />
        <SectionLabel href="#irb" indented label="IRB Statistics" />
        <SectionLabel href="#members" indented label="Members" />
        <SectionLabel
          href="#predicted-recognition-rate"
          indented
          label="Predicted Recognition Rate"
        />
        <SectionLabel
          href="#recognition-rate"
          indented
          label="Recognition Rate"
        />
        <SectionLabel href="#year" indented label="Year" />
      </SectionLabelsContainer>
      <Container>
        <Section>
          <Display>Glossary</Display>
          <Title>
            Methodology and Terms found throghout the Refugee Law Lab Portal
          </Title>
        </Section>
        <hr />
        <Section id="active-members">
          <h2>Active Members</h2>
          <p>
            Active members refer to Members who made at least 20 decisions in a
            year (or range of years).
          </p>
        </Section>
        <hr />
        <Section id="country">
          <h2>Country</h2>
          <p>
            Country means the country in which the principal applicant alleges a
            fear of persecution. In some cases a principal applicant (or
            dependent applicants) may have multiple countries of persecution, in
            which case only the first listed country is reflected in the
            statistics.
          </p>
        </Section>
        <hr />
        <Section id="decisions">
          <h2>Decisions</h2>
          <p>
            All IRB statistics on this website use principal applicant decisions
            on the merits as the unit of analysis.
          </p>
          <p>
            Because we consider only principal applicants, each case counts as
            one decision, regardless of the number of family members covered by
            the case. In other words, a family of 4 making a claim together
            counts as 1 decision. By contrast many other sources of statistics
            about refugee adjudication are based on all claimants (i.e. a family
            of 4 counts as 4 decisions).
          </p>
          <p>
            Because we consider only decisions on the merits, all of our
            statistics exclude cases that are abandoned, withdrawn, or otherwise
            resolved. Our statistics also exclude procedural decisions.
          </p>
          <p>
            Numbers of decisions are reported in bins of 20 to comply with the
            IRB's Small Value Suppression policy. The number shown is the lower
            range of the relevant bin. So, for example, if a decision maker
            shows 61 decisions, the real number is between 61 and 80.
          </p>
        </Section>
        <hr />
        <Section id="irb">
          <h2>IRB Statistics</h2>
          <p>
            The Refugee Law Laboratory obtains data annually from Canada's
            Immigration and Refugee Board (IRB) about outcomes in all refugee
            claims decided through access to information requests. Since 2020,
            that data has been subject to a data sharing agreement that aims to
            protect the privacy of refugee claimants through the IRB's{" "}
            <a href="https://irb.gc.ca/en/statistics/Pages/small-value-suppression.aspx">
              Small Value Suppression Policy
            </a>
            .
          </p>
          <p>
            For details about the data, see the Refugee Law Lab's Refugee Law
            Data page{" "}
            <a href="https://refugeelab.ca/projects/refugee-law-data/">here</a>.
            For scholarship describing the methodology in more detail, see this
            law journal{" "}
            <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=1468717">
              article
            </a>
            .
          </p>
          <p>
            When the IRB provides the requested data, it occasionally also
            provides explanatory notes, in both{" "}
            <a href="https://refugeelab.ca/wp-content/uploads/2022/10/2022-10-Explanatory-Note-to-refugee-acceptance-rates-English.pdf">
              English
            </a>{" "}
            and{" "}
            <a href="https://refugeelab.ca/wp-content/uploads/2022/10/2022-10-Explanatory-Note-to-refugee-acceptance-rates-French.pdf">
              French
            </a>
            , explaining factors that might influence outcomes.
          </p>
        </Section>
        <hr />
        <Section id="members">
          <h2>Members</h2>
          <p>
            Members refer to Members of the IRB's Refugee Protection Division
            who make first instance refugee determinations. In the large
            majority of cases, only one decision-maker is assigned to hear any
            given case. However, it is possible for the IRB to assign more than
            one decision-maker to a case (which would typically be done for
            training purposes). In such circumstances only the first listed
            decision-maker is reflected in the statistics.
          </p>
        </Section>
        <hr />
        <Section id="predicted-recognition-rate">
          <h2>Predicted Recognition Rate</h2>
          <p>
            Predicted recognition rates are calculated based on weighted yearly
            country of origin averages for the cases heard by decision-makers.
          </p>
          <p>
            Some recognition rate variation observed across decision-makers is
            likely due to specialization in particular types of cases. For
            example, some decision-makers may specialize in geographic regions
            with especially high or low refugee claim recognition rates. It
            should also be kept in mind that to enhance efficiency the
            Immigration and Refugee Board has placed increased emphasis on
            streaming cases into different categories, including expedited cases
            that are granted based on paper reviews rather than hearings. The
            proportion of such claims heard by particular decision-makers may
            affect their recognition rates. For further possible explanations
            for variations in recognition rates, please the academic article and
            explanatory notes linked above.
          </p>
        </Section>
        <hr />
        <Section id="recognition-rate">
          <h2>Recognition Rate</h2>
          <p>
            Recognition rates mean the number of refugee claims that are
            granted, divided by the number of refugee claims decided on the
            merits, expressed as a percentage. Cases that are withdrawn,
            abandoned, or resolved through other processes are excluded.
          </p>
        </Section>
        <hr />
        <Section id="year">
          <h2>Year</h2>
          <p>
            Year means the calendar year when the decision was made (which may
            be different from the year when the application was made).
          </p>
        </Section>
      </Container>
    </Layer>
  );
}

export default Glossary;
