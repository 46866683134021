import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { Action, Actions, Banner, Detail, Subtitle, Title } from "../shared/Common";

const DialogContainer = styled.div`
  width: 640px;
  padding: 24px 32px;

  textarea,
  input,
  label {
    font-family: DM Sans;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const MemberInformation = styled.form`
  margin: 8px 0;
  padding: 8px 0;
  display: grid;
  grid-row-gap: 16px;
`;

function NewDecisionMakerDialog({
  open,
  onCancel,
  onSubmit,
  handleChange,
  newMemberValue,
}: {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  handleChange: (name: string, value: string) => void;
  newMemberValue: {
    firstName: string;
    lastName: string;
    background: string;
  };
}) {
  const { firstName, lastName, background } = newMemberValue;

  return (
    <Dialog maxWidth="md" open={open}>
      <DialogContainer>
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title>New decision maker submission</Title>
            <IconButton onClick={() => onCancel()}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <MemberInformation>
            <Subtitle>Personal information</Subtitle>
            <TextField
              name="firstName"
              size="small"
              variant="outlined"
              label="First name"
              style={{ width: "100%" }}
              value={firstName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event.target.name, event.target.value)
              }
            />
            <TextField
              name="lastName"
              size="small"
              variant="outlined"
              label="Last name"
              style={{ width: "100%" }}
              value={lastName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event.target.name, event.target.value)
              }
            />
          </MemberInformation>
          <MemberInformation>
            <Subtitle>How did you hear about this member?</Subtitle>
            <TextareaAutosize
              name="background"
              style={{ padding: "8px", height: "120px" }}
              value={background}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleChange(event.target.name, event.target.value)
              }
            />
          </MemberInformation>
          <Banner style={{ margin: "12px 0" }}>
            <Detail>
              The information submitted on this form will be reviewed by a staff
              member of the Refugee Law Lab before being published
            </Detail>
          </Banner>
          <Actions style={{ marginTop: "12px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSubmit()}
            >
              <Action>Submit</Action>
            </Button>
          </Actions>
        </DialogContent>
      </DialogContainer>
    </Dialog>
  );
}

export default NewDecisionMakerDialog;
