import Jocelyne_Gagne from "../assets/judges/Jocelyne_Gagne.jpg";
import Sandra_Simpson from "../assets/judges/Sandra_Simpson.jpg";
import Simon_Noel from "../assets/judges/Simon_Noel.jpg";
import James_O from "../assets/judges/James_O'Reilly.jpg";
import Richard_Mosley from "../assets/judges/Richard_Mosley.jpg";
import Michael_Phelan from "../assets/judges/Michael_Phelan.jpg";
import Russel_Zinn from "../assets/judges/Russel_Zinn.jpg";
import Catherine_Kane from "../assets/judges/Catherine_Kane.jpg";
import Michael_Manson from "../assets/judges/Michael_Manson.jpg";
import Yvan_Roy from "../assets/judges/Yvan_Roy.jpg";
import Cecily_Strickland from "../assets/judges/Cecily_Strickland.jpg";
import Peter_Annis from "../assets/judges/Peter_Annis.jpg";
import Glennys_McVeigh from "../assets/judges/Glennys_McVeigh.jpg";
import Martine_St from "../assets/judges/Martine_StLouis.jpg";
import Alan_Diner from "../assets/judges/Alan_Diner.jpg";
import Simon_Fothergill from "../assets/judges/Simon_Fothergill.jpg";
import Denis_Gascon from "../assets/judges/Denis_Gascon.jpg";
import Richard_Southcott from "../assets/judges/Richard_Southcott.jpg";
import Patrick_Gleeson from "../assets/judges/Patrick_Gleeson.jpg";
import Susan_Elliott from "../assets/judges/Susan_Elliott.jpg";
import Ann_Marie_McDonald from "../assets/judges/Ann_Marie_McDonald.jpg";
import Roger_Lafreniere from "../assets/judges/Roger_Lafreniere.jpg";
import William_Pentney from "../assets/judges/William_Pentney.jpg";
import Shirzad_Ahmed from "../assets/judges/Shirzad_Ahmed.jpg";
import Sebastien_Grammond from "../assets/judges/Sebastien_Grammond.jpg";
import Paul_Favel from "../assets/judges/Paul_Favel.jpg";
import Elizabeth_Walker from "../assets/judges/Elizabeth_Walker.jpg";
import John_Norris from "../assets/judges/John_Norris.jpg";
import Peter_George_Pamel from "../assets/judges/Peter_George_Pamel.jpg";
import Nicholas_McHaffie from "../assets/judges/Nicholas_McHaffie.jpg";
import Janet_Fuhrer from "../assets/judges/Janet_Fuhrer.jpg";
import Angela_Furlanetto from "../assets/judges/Angela_Furlanetto.jpg";
import Anne_Mactavish from "../assets/judges/Anne_Mactavish.jpg";
import Leonard_Mandamin from "../assets/judges/Leonard_Mandamin.jpg";
import Rene_LeBlanc from "../assets/judges/Rene_LeBlanc.jpg";
import Douglas_Campbell from "../assets/judges/Douglas_Campbell.jpg";
import Luc_Martineau from "../assets/judges/Luc_Martineau.jpg";
import Robert_Barnes from "../assets/judges/Robert_Barnes.jpg";
import Martha_Milczynski from "../assets/judges/Martha_Milczynski.jpg";
import Alexandra_Steele from "../assets/judges/Alexandra_Steele.jpg";
import Avvy_Go from "../assets/judges/Avvy_Go.jpg";
import Mandy_Aylen from "../assets/judges/Mandy_Aylen.jpg";

const judgeGallery = {
  Go: Avvy_Go,
  Aylen: Mandy_Aylen,
  Gagne: Jocelyne_Gagne,
  Simpson: Sandra_Simpson,
  Noel: Simon_Noel,
  "O'Reilly": James_O,
  Mosley: Richard_Mosley,
  Phelan: Michael_Phelan,
  Zinn: Russel_Zinn,
  Kane: Catherine_Kane,
  Manson: Michael_Manson,
  Roy: Yvan_Roy,
  Strickland: Cecily_Strickland,
  Annis: Peter_Annis,
  McVeigh: Glennys_McVeigh,
  "St-Louis": Martine_St,
  Diner: Alan_Diner,
  Fothergill: Simon_Fothergill,
  Gascon: Denis_Gascon,
  Southcott: Richard_Southcott,
  Gleeson: Patrick_Gleeson,
  Elliott: Susan_Elliott,
  MarieMcDonald: Ann_Marie_McDonald,
  Lafreniere: Roger_Lafreniere,
  Pentney: William_Pentney,
  Ahmed: Shirzad_Ahmed,
  Grammond: Sebastien_Grammond,
  Favel: Paul_Favel,
  Walker: Elizabeth_Walker,
  Norris: John_Norris,
  GeorgePamel: Peter_George_Pamel,
  McHaffie: Nicholas_McHaffie,
  Fuhrer: Janet_Fuhrer,
  Furlanetto: Angela_Furlanetto,
  Mactavish: Anne_Mactavish,
  Mandamin: Leonard_Mandamin,
  LeBlanc: Rene_LeBlanc,
  Campbell: Douglas_Campbell,
  Martineau: Luc_Martineau,
  Barnes: Robert_Barnes,
  Milczynski: Martha_Milczynski,
  Steele: Alexandra_Steele,
};

export const getJudgeImage = (id: string) =>
  id in judgeGallery ? judgeGallery[id as keyof typeof judgeGallery] : null;
