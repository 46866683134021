import { CSSProperties } from "react";

export const GRAPH_MARGIN = 32;
export const ALL_INDICATOR = "+ALL"; // null;
// export const ALL_INDICATOR = 0; // null;
export const AVERAGE_COLOR = "darkblue";

// IRB Graph Colors
export const TARGET_MEMBER_COLOR = "FireBrick";
export const IRB_MEMBER_COLOR = "#1da5a524";
export const IRB_MEMBER_LABEL_COLOR = "#1da5a5";


// FC Graph Colors
export const TARGET_JUDGE_COLOR = "FireBrick";
export const FC_MEMBER_COLOR = "#1da5a524";
export const FC_JUDGE_LABEL_COLOR = "#1da5a5";
export const FC_JUDGE_COLOR = "#1da5a524";

export const CONTAINER_HORIZONTAL_PADDING = "0px"; // TODO fix to 64px after mobile
export const CONTAINER_STYLE: CSSProperties = {
  paddingLeft: CONTAINER_HORIZONTAL_PADDING,
  paddingRight: CONTAINER_HORIZONTAL_PADDING,
};

export const pinnedCommunityStyle: CSSProperties = {
  position: "fixed",
  bottom: 0,
  padding: "24px 48px",
  right: 0,
  width: "100%",
  height: "300px",
  backgroundColor: "var(--light-1)",
  margin: 0,
  zIndex: 10,
  overflow: "auto",
  boxShadow: "var(--shadow-basic)",
};

export const IRB_AVAILABLE_YEARS = [
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
];

export const FC_AVAILABLE_YEARS = [
  "1993",
  "1994",
  "1995",
  "1996",
  "1997",
  "1998",
  "1999",
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
];

export const IRB_LATEST_YEAR =
  IRB_AVAILABLE_YEARS[IRB_AVAILABLE_YEARS.length - 1];
export const IRB_EARLIEST_YEAR = IRB_AVAILABLE_YEARS[0];

export const FC_LATEST_YEAR = FC_AVAILABLE_YEARS[FC_AVAILABLE_YEARS.length - 1];
export const FC_EARLIEST_YEAR = FC_AVAILABLE_YEARS[0];
