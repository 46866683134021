import styled from "styled-components";
import UseIndicator from "../util/UseIndicator";

const IndicatorBox = styled.div`
  position: absolute;
  height: 8px;
  top: 12px;
  left: -2px;
  width: 6px;
  background-color: var(--blue);
  z-index: 100;
  pointer-events: none;
  transition: top 150ms ease, height 150ms ease;
`;

function SectionIndicator({ start }: { start: string }) {
  const { indicatorHeight, indicatorOffsetY } = UseIndicator(start);

  return (
    <IndicatorBox
      style={{
        top: `${indicatorOffsetY + 4}px`,
        height: `${indicatorHeight - 8}px`,
      }}
    />
  );
}

export default SectionIndicator;
