import { GraphContainer, MobileGraphsBanner } from "../../../shared/Common";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  ComposedChart,
  Scatter,
  Label,
} from "recharts";
import {
  AVERAGE_COLOR,
  FC_JUDGE_COLOR,
  FC_JUDGE_LABEL_COLOR,
  GRAPH_MARGIN,
  IRB_MEMBER_LABEL_COLOR,
  TARGET_JUDGE_COLOR,
} from "../../../util/constants";
import { LeaveRatePerOverallJRRateLeaveJudgeToolTip } from "./Graphs.Shared";

function RefugeeJRLeaveRatePerOverallRefugeeJRRateLeaveJudge({
  data,
  judgeName,
  showAverage = false,
  fullScreen = false,
}: {
  data?: {
    jr_rate?: number;
    leave_rate?: number;
    judge_rate?: number;
    judge_overall_jr_rate?: number;
    average?: number;
    id?: string;
    name?: string;
  }[];
  judgeName?: string;
  showAverage?: boolean;
  fullScreen?: boolean;
}) {
  return (
    <GraphContainer
      style={{ fontFamily: "DM Sans" }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={data}
          margin={{
            top: GRAPH_MARGIN,
            right: GRAPH_MARGIN,
            bottom: GRAPH_MARGIN,
            left: GRAPH_MARGIN,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <Tooltip
            content={
              <LeaveRatePerOverallJRRateLeaveJudgeToolTip
                units={{
                  rate: "%",
                  predicted: "%",
                  average: "%",
                  targetRate: "%",
                  jr_rate: "%",
                  leave_rate: "%",
                  judge_rate: "%",
                  judge_leave_rate: "%",
                  overall_jr_rate: "%",
                }}
              />
            }
          />
          <Legend
            formatter={(value: string, entry: any) => {
              const { color } = entry;
              return (
                <span
                  style={{
                    color:
                      value === "FC Judges" ? FC_JUDGE_LABEL_COLOR : color,
                  }}
                >
                  {value}
                </span>
              );
            }}
            verticalAlign="top"
            wrapperStyle={{ top: 0, height: 8 }}
          />

          {/* X Axis */}
          <XAxis
            dataKey="leave_rate"
            type="number"
            unit="%"
            label={
              <Label
                value="Refugee JR Leave Rate"
                position="bottom"
                style={{ textAnchor: "middle" }}
              />
            }
            domain={[0, 100]}
          />
          <YAxis
            unit="%"
            type="number"
            label={
              <Label
                angle={-90}
                value="Overall Refugee JR Rate (leave judge)"
                position="insideLeft"
                style={{ textAnchor: "middle" }}
              />
            }
            domain={[0, 100]}
          />

          {/* Y Axis */}
          <Scatter name="Judges" dataKey="jr_rate" fill={FC_JUDGE_COLOR} />

          {judgeName && (
            <Scatter
              name={judgeName}
              dataKey="judge_overall_jr_rate"
              fill={TARGET_JUDGE_COLOR}
            />
          )}

          {showAverage && (
            <Scatter
              name="Average (All Judges)"
              dataKey="average"
              fill={AVERAGE_COLOR}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
      <MobileGraphsBanner />
    </GraphContainer>
  );
}

export default RefugeeJRLeaveRatePerOverallRefugeeJRRateLeaveJudge;
