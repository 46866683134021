import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Judge, JudgeEntry } from "../util/interfaces";
import { Actions, Detail, EmptyResults } from "../shared/Common";

const JUDGE_PAGE_OFFSET = 10;

const Box = styled.div`
  display: grid;
  grid-row-gap: 8px;

  a,
  a:visited {
    color: var(--dark-1);
    width: max-content;
  }

  hr {
    transform: translateY(8px);
  }
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`;

function JudgeCard({ judge }: { judge: JudgeEntry }) {
  const { id, name, merits_rate, merits_total, leave_rate, leave_total } =
    judge;

  return (
    <Box>
      <a href={`federal-court/judge/${id}`}>
        <b>{name}</b>
      </a>
      <Info>
        <InfoItem>
          <Detail>JR Leave Rate</Detail>
          {leave_rate ? <p>{leave_rate}%</p> : <p>N/A</p>}
        </InfoItem>
        <InfoItem>
          <Detail>Total</Detail>
          {leave_total ? <p>{leave_total.toLocaleString()}</p> : <p>N/A</p>}
        </InfoItem>
        <InfoItem>
          <Detail>JR Merit Rate</Detail>
          {merits_rate ? <p>{merits_rate}%</p> : <p>N/A</p>}
        </InfoItem>
        <InfoItem>
          <Detail>Total</Detail>
          {merits_total ? <p>{merits_total.toLocaleString()}</p> : <p>N/A</p>}
        </InfoItem>
      </Info>
      <hr />
    </Box>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const JudgesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 24px;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  margin: 16px 0;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;

function Judges({ judges }: { judges: JudgeEntry[] }) {
  const [page, setPage] = useState(1);
  const [pageJudges, setPageJudges] = useState<JudgeEntry[]>([]);

  useEffect(() => {
    const actualPage = page - 1;
    setPageJudges(
      judges.slice(
        actualPage * JUDGE_PAGE_OFFSET,
        actualPage * JUDGE_PAGE_OFFSET + JUDGE_PAGE_OFFSET
      )
    );
  }, [page, judges]);

  useEffect(() => {
    setPage(1);
  }, [judges]);

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Content>
      {!judges.length ? (
        <EmptyResults message="No judges found" />
      ) : (
        <JudgesContainer>
          {pageJudges.map((judge, index) => (
            <JudgeCard key={`judge-${index}`} judge={judge} />
          ))}
        </JudgesContainer>
      )}
      <Actions style={{ marginTop: "16px", justifyContent: "center" }}>
        <Pagination
          onChange={handlePageChange}
          page={page}
          count={Math.ceil(judges.length / JUDGE_PAGE_OFFSET)}
          color="primary"
        />
      </Actions>
    </Content>
  );
}

export default Judges;
