import {
  Button,
  Container,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Action,
  Actions,
  Detail,
  Display,
  FilterBox,
  LoadingOverlay,
  Message,
  PageFilterBox,
  Section,
  SectionLabel,
  SectionLabelsContainer,
  Stat,
  SubSections,
  Subtitle,
  Title,
} from "../shared/Common";
import {
  FCYearEntry,
  FCQueryV2Response,
  Judge,
  JudgeEntry,
  JudgeSortOption,
  FCJudgeQueryResponseV2,
} from "../util/interfaces";
import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import SectionIndicator from "./SectionIndicator";
import Judges from "./Judges";
import { parsePercent } from "../util/helpers";
import { ALL_INDICATOR } from "../util/constants";
import StatAvg from "./StatAvg";
import RefugeeJRLeaveRatePerOverallRefugeeJRRateLeaveJudge from "./graphs/DecisionMaker/RefugeeJRLeaveRatePerOverallRefugeeJRRateLeaveJudge";
import RefugeeJRLeaveRatePerYear from "./graphs/DecisionMaker/RefugeeJRLeaveRatePerYear";
import { getJudges, sortJudges } from "../util/fc/fc.helpers";
import FCLeaveRatePerMeritsRate from "./graphs/Federal Court/FCLeaveRatePerMeritsRate";
import CountUp from "react-countup";
import { Clear, Search } from "@mui/icons-material";
import FCLeaveRateHorizontalGraph from "./graphs/Federal Court/FCLeaveRateHorizontalGraph";
import FCMeritsHorizontalGraph from "./graphs/Federal Court/FCMeritsHorizontalGraph";
import {
  getDistinctYears,
  getSummary,
  getSummaryJudge,
  getYearly,
  getYearlyJudge,
} from "../services/db";
import { useDb } from "../services/UseDb";

const Layer = styled.div`
  display: flex;
`;

const JudgesOptions = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 3fr;
  grid-column-gap: 16px;
  margin: 16px 0px;
  height: 40px;

  input,
  label {
    font-family: DM Sans;
  }

  div {
    height: 40px;
  }

  @media (max-width: 700px) {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(3, 1fr);

    div:nth-of-type(1) {
      grid-column: span 3;
    }

    div:nth-of-type(2) {
      grid-column: auto / span 1;
    }

    div:nth-of-type(3) {
      grid-column: auto / span 2;
    }

    height: min-content;
  }
`;

const PercentStatBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  height: 220px;
  transition: box-shadow 250ms ease;

  :hover {
    box-shadow: var(--shadow-basic);
  }

  @media (max-width: 700px) {
    height: 148px;
  }
`;

const CountStatBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  transition: box-shadow 250ms ease;

  :hover {
    box-shadow: var(--shadow-basic);
  }

  p {
    margin-left: 32px;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    p:nth-of-type(1) {
      align-self: flex-end;
    }
  }
`;

const StatTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const KeyStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;

  h2,
  label,
  p {
    color: var(--light-1);
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const TopStatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 16px;

  @media (max-width: 700px) {
    grid-template-columns: none;
    grid-template-rows: repeat(3, 1fr);
  }
`;

const BottomStatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;

  @media (max-width: 700px) {
    grid-template-columns: none;
    grid-row-gap: 16px;
    grid-template-rows: repeat(2, 1fr);
  }
`;

function FederalCourt({ duckDbConn }: { duckDbConn: AsyncDuckDBConnection }) {
  // Judge source info
  const [judgesInfo, setJudgesInfo] = useState<Judge[]>([]);

  const [loading, setLoading] = useState(true);

  // Available years for data
  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [earliestYear, setEarliestYear] = useState<number>(0);
  const [latestYear, setLatestYear] = useState<number>(0);

  // Filters for page
  const [showDataFilter, setShowDataFilter] = useState(true);
  const [yearPageFilter, setYearPageFilter] = useState(ALL_INDICATOR);

  // Summary data
  const [yearSummary, setYearSummary] = useState<FCYearEntry>({
    overall_jr_rate: 0,
    merits_rate: 0,
    jr_leave_rate: 0,
    merits_total: 0,
    leave_total: 0,
    jr_total: 0,
  });
  const [perYearEntries, setPerYearEntries] = useState<FCYearEntry[]>([]);

  useEffect(() => {
    const judges = getJudges();
    setJudgesInfo(judges);
  }, []);

  // Graph data
  const [graphData, setGraphData] = useState<JudgeEntry[]>([]);

  // Data queries
  const { loading: summaryDataLoading, data: summaryData } = useDb(
    getSummary(
      duckDbConn,
      yearPageFilter === ALL_INDICATOR ? null : parseInt(yearPageFilter),
      yearPageFilter === ALL_INDICATOR ? null : parseInt(yearPageFilter)
    ),
    [yearPageFilter]
  );

  const { loading: judgesLoading, data: judgesData } = useDb(
    yearPageFilter === ALL_INDICATOR
      ? getSummaryJudge(duckDbConn, null)
      : getYearlyJudge(duckDbConn, null, parseInt(yearPageFilter)),
    [yearPageFilter]
  );

  const { loading: perYearLoading, data: perYearData } = useDb(
    getYearly(duckDbConn, null, null, "ASC"),
    []
  );

  const { loading: availableYearsLoading, data: availableYearsData } = useDb(
    getDistinctYears(duckDbConn),
    []
  );
  // end of data queries

  // Judge lists sourced from judgeList -> judgeDisplayList
  const [judgeDisplayList, setJudgeDisplayList] = useState<JudgeEntry[]>([]);
  const [filteredJudges, setFilteredJudges] = useState<JudgeEntry[]>([]);
  const [sortedJudges, setSortedJudges] = useState<JudgeEntry[]>([]);
  const [judgeList, setJudgeList] = useState<JudgeEntry[]>([]);

  // Filter and sorting states
  const [judgeSearchFilter, setJudgeSearchFilter] = useState("");
  const [judgeSortOption, setJudgeSortOption] =
    useState<JudgeSortOption>("name");

  //--------------------------------------------------------------------------------
  // Effects

  // List
  useEffect(() => {
    let searchResults = [...judgeList];
    searchResults = judgeList.filter(({ name }) =>
      name.toUpperCase().includes(judgeSearchFilter.toUpperCase())
    );
    setFilteredJudges(searchResults);
  }, [judgeSearchFilter, judgeList]);

  useEffect(() => {
    const sortedMembers = sortJudges(judgeList, judgeSortOption);
    setSortedJudges(sortedMembers);
  }, [judgeSortOption, judgeList]);

  useEffect(() => {
    setJudgeDisplayList(judgeSearchFilter ? filteredJudges : sortedJudges);
  }, [sortedJudges, filteredJudges, judgeSearchFilter]);

  useEffect(() => {
    setEarliestYear(Math.min(...availableYears));
    setLatestYear(Math.max(...availableYears));
  }, [availableYears]);

  // Handle of query results
  useEffect(() => {
    if (summaryData) {
      const {
        jr_granted,
        leave_rate,
        leave_total,
        merits_rate,
        merits_total,
        overall_rate,
        pending_jr,
      }: FCQueryV2Response = summaryData;

      setYearSummary({
        overall_jr_rate: parsePercent(overall_rate),
        merits_rate: parsePercent(merits_rate),
        jr_leave_rate: parsePercent(leave_rate),
        merits_total: Number(merits_total || 0),
        leave_total: Number(leave_total || 0),
        jr_total: jr_granted + pending_jr || 0,
      });
    }
  }, [summaryData]);

  useEffect(() => {
    if (judgesData) {
      const parsedGraphData: JudgeEntry[] = judgesData.map(
        ({
          leave_total,
          merits_rate,
          merits_total,
          leave_rate,
          jr_granted,
          judge,
          pending_jr,
          overall_rate,
        }: FCJudgeQueryResponseV2) => ({
          jr_rate: parsePercent(overall_rate),
          jr_total: jr_granted + pending_jr,
          leave_rate: parsePercent(leave_rate),
          leave_total: Number(leave_total),
          merits_rate: parsePercent(merits_rate),
          merits_total: Number(merits_total),
          id: judge,
          name:
            judgesInfo.find((info) => info.id === judge)?.displayName || judge,
        })
      );

      setGraphData(parsedGraphData);
      setJudgeList(parsedGraphData);
    }
  }, [judgesData, judgesInfo]);

  useEffect(() => {
    if (perYearData) {
      const formattedYears: FCYearEntry[] = perYearData.map(
        ({
          leave_total,
          leave_rate,
          overall_rate,
          jr_granted,
          pending_jr,
          merits_total,
          merits_rate,
          year,
        }: FCQueryV2Response) => ({
          overall_jr_rate: parsePercent(overall_rate),
          merits_rate: parsePercent(merits_rate),
          jr_leave_rate: parsePercent(leave_rate),
          merits_total: Number(merits_total),
          leave_total: Number(leave_total),
          jr_total: jr_granted + pending_jr,
          year: Number(year),
        })
      );

      setPerYearEntries(formattedYears);
    }
  }, [perYearData]);

  useEffect(() => {
    if (availableYearsData) {
      setAvailableYears(availableYearsData.sort().reverse());
    }
  }, [availableYearsData]);

  // Loading
  useEffect(() => {
    setLoading(
      summaryDataLoading ||
        judgesLoading ||
        perYearLoading ||
        availableYearsLoading
    );
  }, [
    summaryDataLoading,
    judgesLoading,
    perYearLoading,
    availableYearsLoading,
  ]);

  return (
    <Layer>
      <SectionLabelsContainer>
        <Detail>Federal Court</Detail>
        <SectionIndicator start="about" />
        <SectionLabel href="#about" indented label="About" />
        <SectionLabel href={`#key-data`} indented label={"Key Data"} />
        <SubSections>
          <Detail>JR Leave Rate</Detail>
          <Detail>JR Merits Rate</Detail>
          <Detail>Overall JR Rate</Detail>
          <Detail>Number of JR Leave Decisions</Detail>
          <Detail>Number of JR Merits Decisions</Detail>
        </SubSections>
        <SectionLabel href={`#yearly-data`} indented label={"Yearly Data"} />
        <SubSections>
          <Detail>Rates per year</Detail>
          {/* <Detail>Number of decisions per year</Detail> */}
        </SubSections>
        <SectionLabel href={`#judge-data`} indented label={"Judge Data"} />
        <SubSections>
          <Detail>Refugee JR Leave Rates per Judge</Detail>
          <Detail>Refugee JR Merits Rates per Judge</Detail>
          <Detail>JR Leave Rate per Overall JR Rate (leave judge)</Detail>
        </SubSections>
        <SectionLabel href="#judges" indented label="Judges" />
      </SectionLabelsContainer>
      <Container>
        <Section id="about">
          <Display>Federal Court</Display>
          <p>
            The Federal Court hears judicial reviews of all matters under
            Canada's Immigration and Refugee Protection Act, including
            applications for judicial review of refugee determinations by the
            Immigration and Refugee Board (IRB's). While the Court also hears
            some other matters (e.g. intellectual properly, other areas of
            Federal administrative law, some areas of Aboriginal law, maritime
            law, etc), immigration and refugee law represents the bulk of
            Court's case law.
          </p>
          <p>
            Applications for judicial review in refugee cases proceed in two
            steps. First, applicants must seek “leave” or permission for the
            Federal Court to hear the case. The test for leave is whether a
            reasonably arguable case has been made out. No reasons are provided
            for leave decisions, and if leave is denied there is no possibility
            of a further appeal. Second, if leave is granted, the judicial
            review is sent to a determination on the merits, where a hearing is
            held, usually before a different judge than the judge granting
            leave. At this stage, applicants must persuade the Court that the
            application should be granted on a balance of probabilities. Reasons
            are usually provided by the Court. If the application for judicial
            review is granted, the IRB's decision will be set aside and the
            matter will usually be sent back to the IRB for redetermination. The
            parties cannot appeal a Federal Court decision on the merits unless
            the judge issuing the decision allows them to by certifying that
            there is a question of general importance that transcends the
            interests of the parties. The statistics below were calculated using
            methodologies described in these articles from{" "}
            <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2027517">
              2012
            </a>
            ,{" "}
            <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3249723">
              2018
            </a>{" "}
            and{" "}
            <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4322881">
              2023
            </a>
            .
          </p>
          <Actions>
            <Button
              style={{ marginLeft: "12px" }}
              variant="contained"
              color="primary"
            >
              <a href="#judges">
                <Action>Search judges</Action>
              </a>
            </Button>
          </Actions>
        </Section>
        <hr />
        <div style={{ position: "relative" }}>
          {showDataFilter && (
            <PageFilterBox>
              <Detail style={{ marginRight: "12px" }}>Filter by year</Detail>
              <Select
                size="small"
                onChange={(e) => setYearPageFilter(e.target.value)}
                value={yearPageFilter}
              >
                <MenuItem value={ALL_INDICATOR}>
                  <Action>
                    All ({earliestYear} to {latestYear})
                  </Action>
                </MenuItem>
                {availableYears.map((year) => (
                  <MenuItem key={`y-${year}`} value={year}>
                    <Action>{year}</Action>
                  </MenuItem>
                ))}
              </Select>
            </PageFilterBox>
          )}
          <Section id="key-data">
            <Display>Key Data</Display>
            <Title>
              from{" "}
              {yearPageFilter === ALL_INDICATOR ? (
                <>
                  <b>{earliestYear}</b> to <b>{latestYear}</b>
                </>
              ) : (
                <b>{yearPageFilter}</b>
              )}
            </Title>
            <KeyStatsContainer>
              <TopStatsContainer>
                <PercentStatBox style={{ backgroundColor: "#AC346D" }}>
                  <StatTitleContainer>
                    <h2>Refugee JR Leave Rate</h2>
                  </StatTitleContainer>
                  <StatAvg stat={yearSummary.jr_leave_rate} />
                </PercentStatBox>
                <PercentStatBox style={{ backgroundColor: "#2D7299" }}>
                  <StatTitleContainer>
                    <h2>Refugee JR Merits Rate</h2>
                    <Subtitle>Where leave granted</Subtitle>
                  </StatTitleContainer>
                  <StatAvg stat={yearSummary.merits_rate} />
                </PercentStatBox>
                <PercentStatBox style={{ backgroundColor: "#AC8435" }}>
                  <StatTitleContainer>
                    <h2>Overall Refugee JR Rate</h2>
                  </StatTitleContainer>
                  <StatAvg stat={yearSummary.overall_jr_rate} />
                </PercentStatBox>
              </TopStatsContainer>
              <BottomStatsContainer>
                <CountStatBox style={{ backgroundColor: "#4F4F4F" }}>
                  <StatTitleContainer>
                    <h2>Number of Refugee JR Leave Decisions</h2>
                  </StatTitleContainer>
                  <Stat>
                    <CountUp
                      preserveValue
                      end={yearSummary.leave_total}
                      formattingFn={(n) => n.toLocaleString()}
                      duration={0.25}
                    />
                  </Stat>
                </CountStatBox>
                <CountStatBox style={{ backgroundColor: "#494A17" }}>
                  <StatTitleContainer>
                    <h2>Number of Refugee JR Merits Decisions</h2>
                  </StatTitleContainer>
                  <Stat>
                    <CountUp
                      preserveValue
                      end={yearSummary.merits_total}
                      formattingFn={(n) => n.toLocaleString()}
                      duration={0.25}
                    />
                  </Stat>
                </CountStatBox>
              </BottomStatsContainer>
            </KeyStatsContainer>
          </Section>
          <Section id="yearly-data">
            <Display>Yearly Data</Display>
            <Title>
              from{" "}
              {yearPageFilter === ALL_INDICATOR ? (
                <>
                  <b>{earliestYear}</b> to <b>{latestYear}</b>
                </>
              ) : (
                <b>{yearPageFilter}</b>
              )}
            </Title>
            <div>
              <h2>Rates per year</h2>
              <RefugeeJRLeaveRatePerYear
                selectedYear={yearPageFilter}
                data={perYearEntries.map(
                  ({ merits_rate, jr_leave_rate, year }) => ({
                    jr_leave_rate,
                    merits_rate,
                    year: year!,
                  })
                )}
              />
            </div>
            {/* <div>
                    <h2>Number of decisions per year</h2>
                    <DecisionsPerYear
                      data={perYearEntries.map(({ year, case_count }) => ({
                        count: case_count!,
                        year: year!,
                      }))}
                    />
                  </div> */}
          </Section>
          <Section id="judge-data">
            <Display>Judge Data</Display>
            <Title>
              from{" "}
              {yearPageFilter === ALL_INDICATOR ? (
                <>
                  <b>{earliestYear}</b> to <b>{latestYear}</b>
                </>
              ) : (
                <b>{yearPageFilter}</b>
              )}
            </Title>
            <div>
              <h2>Refugee JR Leave Rates per Judge</h2>
              <FCLeaveRateHorizontalGraph
                judges={[...graphData]}
                average={yearSummary.jr_leave_rate}
              />
            </div>
            <div>
              <h2>Refugee JR Merits Rates per Judge</h2>
              <FCMeritsHorizontalGraph
                judges={[...graphData]}
                average={yearSummary.merits_rate}
              />
            </div>
            <div>
              <h2>JR Leave Rate per Overall JR Rate (leave judge)</h2>
              <RefugeeJRLeaveRatePerOverallRefugeeJRRateLeaveJudge
                showAverage
                data={[
                  ...graphData.map(({ id, jr_rate, leave_rate, name }) => ({
                    id,
                    jr_rate,
                    leave_rate,
                    name,
                  })),
                  {
                    leave_rate: yearSummary.jr_leave_rate,
                    average: yearSummary.overall_jr_rate,
                  },
                ]}
              />
            </div>
            <div>
              <h2>JR Leave Rate per Merits Rate</h2>
              <FCLeaveRatePerMeritsRate
                showAverage
                data={[
                  ...graphData.map(({ id, leave_rate, name, merits_rate }) => ({
                    id,
                    merits_rate,
                    leave_rate,
                    name,
                  })),
                  {
                    leave_rate: yearSummary.jr_leave_rate,
                    average: yearSummary.merits_rate,
                  },
                ]}
              />
            </div>
          </Section>
          <Section id="judges">
            <Display>Judges</Display>
            <Title>
              from{" "}
              {yearPageFilter === ALL_INDICATOR ? (
                <>
                  <b>{earliestYear}</b> to <b>{latestYear}</b>
                </>
              ) : (
                <b>{yearPageFilter}</b>
              )}
            </Title>
            <JudgesOptions>
              <TextField
                size="small"
                variant="outlined"
                label="Search by name"
                style={{ width: "100%" }}
                value={judgeSearchFilter}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setJudgeSearchFilter(event.target.value)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {judgeSearchFilter !== "" ? (
                        <IconButton
                          size="small"
                          aria-label="search"
                          onClick={() => setJudgeSearchFilter("")}
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      ) : (
                        <Search fontSize="small" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Message
                text={`${judgeDisplayList.length} ${
                  judgeDisplayList.length === 1 ? "result" : "results"
                }`}
                type="subtitle"
              />
              <Actions>
                <FilterBox>
                  <Detail>Sort by</Detail>
                  <Select
                    size="small"
                    onChange={(e) =>
                      setJudgeSortOption(e.target.value as JudgeSortOption)
                    }
                    value={judgeSortOption}
                  >
                    <MenuItem value="name">
                      <Action>Name</Action>
                    </MenuItem>
                    <MenuItem value="merits_rate">
                      <Action>Merits rate</Action>
                    </MenuItem>
                    <MenuItem value="merits_total">
                      <Action>Merits decisions</Action>
                    </MenuItem>
                    <MenuItem value="leave_rate">
                      <Action>JR Leave rate</Action>
                    </MenuItem>
                    <MenuItem value="leave_total">
                      <Action>JR Leave decisions</Action>
                    </MenuItem>
                  </Select>
                </FilterBox>
              </Actions>
            </JudgesOptions>
            <Judges judges={judgeDisplayList} />
          </Section>
        </div>
      </Container>
    </Layer>
  );
}

export default FederalCourt;
