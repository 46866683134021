import { Container, Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/icon-light-color.svg";
import { Action, DesktopOnly, MobileOnly, Subtitle } from "../shared/Common";
import { useState } from "react";
import MobileNav from "./MobileNav";

const Layer = styled.div`
  background-color: var(--dark-1);
  color: var(--light-1);
  padding: 6px 0;
  z-index: 10;

  a,
  p,
  label,
  button {
    color: var(--light-1);
  }

  position: fixed;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 8px;
  }

  a,
  a:visited {
    color: var(--light-1);
  }
`;

const Logo = styled.img`
  width: 32px;
`;

const Options = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 12px;
  }
`;

const Dropdown = styled.div`
  ul li {
    font-family: DM Sans;
  }
`;

function Nav() {
  const [anchorEl, setAnchorEl] = useState<
    null | (EventTarget & HTMLButtonElement)
  >();
  const menuOpen = Boolean(anchorEl);
  const history = useHistory();

  return (
    <Layer>
      <DesktopOnly>
        <Container>
          <Content>
            <Link to="/" style={{ textDecoration: "none" }}>
              <LogoContainer>
                <Logo src={logo} />
                <Subtitle>Refugee Law Lab Portal</Subtitle>
              </LogoContainer>
            </Link>
            <Links>
              <Link to="/immigration-refugee-board">
                <Action>Immigration Refugee Board</Action>
              </Link>
              <Link to="/federal-court">
                <Action>Federal Court</Action>
              </Link>
              <a href="https://crs1.apps01.yorku.ca/machform/view.php?id=44714">
                <Button color="secondary" variant="outlined">
                  <Action>Submit feedback</Action>
                </Button>
              </a>
            </Links>
          </Content>
        </Container>
      </DesktopOnly>
      <MobileOnly>
        <MobileNav />
      </MobileOnly>
    </Layer>
  );
}

const Links = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 16px;
`;

export default Nav;
