import { ArrowLeft, ArrowRight, Person } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  AVERAGE_COLOR,
  TARGET_MEMBER_COLOR,
  IRB_MEMBER_COLOR,
} from "../../util/constants";
import { $Member } from "../../util/interfaces";
import { Action, Detail, Subtitle } from "../../shared/Common";

const DOT_SIZE = 8;
const MEMBER_DOT_SIZE = 12;
const AVG_LINE_SIZE = 12;
const LABEL_SIZE = 12;
const SLIDE_OFFSET = 2;

const Layer = styled.div`
  position: relative;
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  padding: 64px;
  width: 100%;
  align-items: center;

  @media (max-width: 700px) {
    padding: 32px;
  }
`;

const GraphContainer = styled.div`
  margin-top: 48px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 700px) {
    width: 85%;
  }
`;

const LeftMarker = styled.div`
  height: 32px;
  width: 1px;
  background-color: black;
  transform: translateY(16px);

  p {
    margin: 0;
    position: absolute;
    transition: transform 350ms ease;
    bottom: 8px;
    left: -36px;
  }
`;

const RightMarker = styled.div`
  height: 32px;
  width: 1px;
  background-color: black;
  transform: translateY(16px);

  p {
    margin: 0;
    position: absolute;
    transition: transform 350ms ease;
    bottom: 8px;
    right: -48px;
  }
`;

const DataContainer = styled.div`
  height: 64px;
  border-bottom: solid 1px var(--grey-1);
  width: 100%;
  position: relative;
`;

const MemberDot = styled.div`
  height: ${DOT_SIZE}px;
  width: ${DOT_SIZE}px;
  background-color: ${IRB_MEMBER_COLOR};
  border-radius: 100%;
  position: absolute;
  transition: transform 350ms ease;
  bottom: -4px;
`;

const TargetMemberDot = styled.div`
  height: ${MEMBER_DOT_SIZE}px;
  width: ${MEMBER_DOT_SIZE}px;
  background-color: ${TARGET_MEMBER_COLOR};
  opacity: 1;
  position: absolute;
  transition: transform 350ms ease;
  border-radius: 100%;
  bottom: -${MEMBER_DOT_SIZE / 2}px;
  display: flex;
  align-items: flex-start;
`;

const AvgDot = styled.div`
  height: ${MEMBER_DOT_SIZE}px;
  width: ${MEMBER_DOT_SIZE}px;
  background-color: ${AVERAGE_COLOR};
  opacity: 1;
  position: absolute;
  transition: transform 350ms ease;
  border-radius: 100%;
  bottom: -${AVG_LINE_SIZE / 2}px;
  display: flex;
  align-items: flex-start;
`;

const LabelsContainer = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: auto auto auto;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LabelColor = styled.div`
  margin-right: 4px;
  height: ${LABEL_SIZE}px;
  width: ${LABEL_SIZE}px;
  border-radius: 100%;
`;

const StatLabel = styled.div`
  padding: 4px 8px;
  height: max-content;
  width: max-content;
  background-color: var(--light-1);

  p {
    margin: 0;
  }
`;

const BlueLabel = styled.div`
  position: absolute;
  left: 24px;
  top: 60px;
  display: flex;
  align-items: center;

  @media (max-width: 700px) {
    top: -12px;
    left: calc(50% - 84px);
  }
`;

const BlueLabelBox = styled.div`
  width: 84px;
`;

const DotsBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DotsGradient = styled.div`
  width: 100%;
  height: 8px;
  background: linear-gradient(0.25turn, #1da5a524, #1da5a5df);
`;

const SlideBox = styled.div`
  display: grid;
  margin-top: 124px;
  grid-template-columns: repeat(7, auto);
  grid-gap: 32px;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 700px) {
    display: none;
  }
`;

function HorizontalGraph({
  members,
  memberRate,
  memberName,
  average,
}: {
  members: $Member[];
  memberRate?: number;
  memberName?: string;
  average: number;
}) {
  const dotsContainer = useRef<HTMLDivElement>(null);
  const [dotsWidth, setDotsWidth] = useState(0);
  const [rates, setRates] = useState<number[]>([]);
  const [membersList, setMembersList] = useState<$Member[]>([]);
  const [memberIndex, setMemberIndex] = useState(0);
  const [offSet, setOffset] = useState(0);
  const [neighborMembers, setNeighborMembers] = useState<$Member[]>([]);

  const calculateOffset = (percent: number, width: number) => {
    return (dotsWidth * percent) / 100 - width / 2;
  };

  useEffect(() => {
    let list = members.sort((a, b) => a.rate! - b.rate!);

    const sortedRates = list.map(({ rate }) => rate!);
    const targetRate = memberRate || average;

    setRates(sortedRates);

    let insertPos = 0;
    for (let i in sortedRates) {
      if (sortedRates[i] > targetRate!) {
        insertPos = parseInt(i);
        setMemberIndex(parseInt(i));
        break;
      }
    }

    const center: $Member = {
      firstName: memberName || "Average",
      memberId: "",
      lastName: "",
    };

    list.splice(insertPos, 0, center);

    setMembersList([...list]);
  }, [members, average, memberName, memberRate]);

  useEffect(() => {
    const neighbors = membersList.slice(
      memberIndex - SLIDE_OFFSET + offSet,
      memberIndex + SLIDE_OFFSET + 1 + offSet
    );

    const neighborsList = neighbors;

    setNeighborMembers(neighborsList);
  }, [memberIndex, membersList, memberName, offSet]);

  useEffect(() => {
    if (dotsContainer.current) {
      setDotsWidth(dotsContainer.current.offsetWidth);
    }
  }, []);

  return (
    <Layer>
      <LabelsContainer>
        {memberName && (
          <LabelContainer>
            <LabelColor style={{ backgroundColor: TARGET_MEMBER_COLOR }} />
            <Subtitle>{memberName}</Subtitle>
          </LabelContainer>
        )}
        <LabelContainer>
          <LabelColor style={{ backgroundColor: "#1da5a5" }} />
          <Subtitle>IRB Members (20+ decisions)</Subtitle>
        </LabelContainer>
        <LabelContainer>
          <LabelColor style={{ backgroundColor: AVERAGE_COLOR }} />
          <Subtitle>IRB Average (All members)</Subtitle>
        </LabelContainer>
      </LabelsContainer>
      <GraphContainer>
        <LeftMarker>
          <p>0%</p>
        </LeftMarker>
        <DataContainer ref={dotsContainer}>
          {rates.map((percent, index) => (
            <MemberDot
              key={`dot-${index}`}
              style={{
                transform: `translateX(${calculateOffset(
                  percent,
                  DOT_SIZE
                )}px)`,
              }}
            />
          ))}
          <AvgDot
            style={{
              transform: `translateX(${calculateOffset(
                average,
                AVG_LINE_SIZE
              )}px)`,
            }}
          >
            <StatLabel
              style={
                average > 50
                  ? {
                      transform: `translate(calc(-100% + ${
                        AVG_LINE_SIZE / 2 + 1
                      }px), 24px)`,
                      borderRight: `solid 1px ${AVERAGE_COLOR}`,
                    }
                  : {
                      transform: `translate(${AVG_LINE_SIZE / 2 - 1}px, 24px)`,
                      borderLeft: `solid 1px ${AVERAGE_COLOR}`,
                    }
              }
            >
              <p>{average}%</p>
            </StatLabel>
          </AvgDot>
          {memberName && (
            <TargetMemberDot
              style={{
                transform: `translateX(${calculateOffset(
                  memberRate || 0,
                  MEMBER_DOT_SIZE
                )}px)`,
              }}
            >
              <StatLabel
                style={
                  (memberRate || 0) > 50
                    ? {
                        transform: `translate(calc(-100% + ${
                          MEMBER_DOT_SIZE / 2
                        }px), -40px)`,
                        borderRight: `solid 1px ${TARGET_MEMBER_COLOR}`,
                      }
                    : {
                        transform: `translate(${MEMBER_DOT_SIZE / 2}px, -40px)`,
                        borderLeft: `solid 1px ${TARGET_MEMBER_COLOR}`,
                      }
                }
              >
                <p>{memberRate}%</p>
              </StatLabel>
            </TargetMemberDot>
          )}
        </DataContainer>
        <RightMarker>
          <p>100%</p>
        </RightMarker>
      </GraphContainer>
      <BlueLabel>
        <Person style={{ marginRight: "12px" }} />
        <BlueLabelBox>
          <DotsBox>
            <Detail>1</Detail>
            <Detail>5</Detail>
            <Detail style={{ marginRight: "-16px" }}>10+</Detail>
          </DotsBox>
          <DotsGradient />
        </BlueLabelBox>
      </BlueLabel>
      <SlideBox>
        {neighborMembers.map(({ firstName, lastName, memberId, rate }, index) =>
          (memberName && firstName === memberName) ||
          firstName === "Average" ? (
            <div
              key={`${memberId}-${index}`}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Detail
                style={{
                  color: "var(--light-1)",
                  backgroundColor:
                    firstName === "Average"
                      ? AVERAGE_COLOR
                      : TARGET_MEMBER_COLOR,
                  padding: "4px 8px",
                }}
              >
                {firstName} {lastName}
              </Detail>
              <Detail style={{ marginTop: "4px", fontWeight: "300" }}>
                {memberRate || average}%
              </Detail>
            </div>
          ) : (
            <div
              key={`${memberId}-${index}`}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <a href={`/immigration-refugee-board/member/${memberId}`}>
                <Action>
                  {firstName} {lastName}
                </Action>
              </a>
              <Detail style={{ marginTop: "4px", fontWeight: "300" }}>
                {rate}%
              </Detail>
            </div>
          )
        )}
        <IconButton
          style={{
            position: "absolute",
            left: "24px",
            backgroundColor: "var(--light-1)",
            boxShadow: "var(--shadow-basic)",
          }}
          size="small"
          onClick={() => setOffset(offSet - 1)}
        >
          <ArrowLeft color="primary" />
        </IconButton>
        <IconButton
          color="primary"
          style={{
            position: "absolute",
            right: "24px",
            backgroundColor: "var(--light-1)",
            boxShadow: "var(--shadow-basic)",
          }}
          size="small"
          onClick={() => setOffset(offSet + 1)}
        >
          <ArrowRight />
        </IconButton>
      </SlideBox>
    </Layer>
  );
}

export default HorizontalGraph;
