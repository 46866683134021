import { Button, Container, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/icon-light-color.svg";
import { Action, Subtitle, Title } from "../shared/Common";
import { useState } from "react";
import Menu from "@mui/icons-material/Menu";
import { Close } from "@mui/icons-material";

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 8px;
  }

  a,
  a:visited {
    color: var(--light-1);
  }
`;

const Logo = styled.img`
  width: 32px;
`;

const OptionsContainer = styled.div`
  position: fixed;
  z-index: 90;
  height: calc(100vh - var(--nav-height));
  width: 100vw;
  top: var(--nav-height);
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  padding: 24px 16px;
  pointer-events: all;

  transform: translateX(-100vw);
  transition: transform 350ms ease;
`;

const Options = styled.div`
  display: grid;
  grid-gap: 24px;

  a {
    width: max-content;
  }
`;

const OptionsGroup = styled.div`
  display: grid;
  grid-gap: 8px;
`;

function MobileNav() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Container>
      <Content>
        <Link to="/" style={{ textDecoration: "none" }}>
          <LogoContainer>
            <Logo src={logo} />
            <Subtitle>Refugee Law Lab Portal</Subtitle>
          </LogoContainer>
        </Link>
        <IconButton onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <Close /> : <Menu />}
        </IconButton>
        <OptionsContainer
          style={menuOpen ? { transform: "translateY(0)" } : {}}
        >
          <Options>
            <OptionsGroup>
              <Title>Court / Tribunal</Title>
              <Link
                onClick={() => setMenuOpen(false)}
                to="/immigration-refugee-board"
              >
                <Action>Immigration Refugee Board</Action>
              </Link>
              <Link onClick={() => setMenuOpen(false)} to="/federal-court">
                <Action>Federal Court</Action>
              </Link>
            </OptionsGroup>
            <OptionsGroup>
              <Title>Contact</Title>
              <a href="https://twitter.com/RefugeeLawLab">
                <Action>Twitter</Action>
              </a>
              <a href="https://refugeelab.ca/contact/">
                <Action>Contact</Action>
              </a>
            </OptionsGroup>
            <OptionsGroup>
              <Title>Legal</Title>
              <a href="https://refugeelab.ca/website-privacy-policy/">
                <Action>Privacy Policy</Action>
              </a>
              <Link to="/terms-of-use">
                <Action>Terms of Use</Action>
              </Link>
            </OptionsGroup>
            <a href="https://crs1.apps01.yorku.ca/machform/view.php?id=44714">
              <Button color="secondary" variant="outlined">
                <Action>Submit feedback</Action>
              </Button>
            </a>
            {/* <Link onClick={() => setMenuOpen(false)} to="/search">
              <Action>Search</Action>
            </Link>
            <Link onClick={() => setMenuOpen(false)} to="/login">
              <Action>Log in</Action>
            </Link> */}
          </Options>
        </OptionsContainer>
      </Content>
    </Container>
  );
}

export default MobileNav;
