import { CustomTooltip, GraphContainer, MobileGraphsBanner } from "../../../shared/Common";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LineChart,
  Line,
  Label,
  Dot,
} from "recharts";
import { ALL_INDICATOR, GRAPH_MARGIN } from "../../../util/constants";
import { FCYearEntry } from "../../../util/interfaces";

function RefugeeJRLeaveRatePerYear({
  data,
  selectedYear,
}: {
  data?: {
    year: number;
    jr_leave_rate: number;
    merits_rate: number;
  }[];
  selectedYear?: string;
}) {
  const CustomizedDot = (props: any) => {
    const { cx, cy, stroke, payload } = props;

    if (
      selectedYear &&
      selectedYear !== ALL_INDICATOR &&
      payload.date === selectedYear
    ) {
      return (
        <Dot
          r={6}
          stroke={stroke}
          strokeWidth={4}
          fill="white"
          cy={cy}
          cx={cx}
        />
      );
    }

    return <Dot r={4} stroke={stroke} fill="white" cy={cy} cx={cx} />;
  };

  return (
    <GraphContainer style={{ fontFamily: "DM Sans" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            bottom: GRAPH_MARGIN,
            left: GRAPH_MARGIN,
            right: GRAPH_MARGIN,
            top: GRAPH_MARGIN,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis
            dataKey="year"
            label={
              <Label
                value="Year"
                position="bottom"
                style={{ textAnchor: "middle" }}
              />
            }
          />
          <YAxis
            unit="%"
            type="number"
            label={
              <Label
                angle={-90}
                value="Rate"
                position="insideLeft"
                style={{ textAnchor: "middle" }}
              />
            }
            domain={[0, 100]}
          />

          <Tooltip
            content={
              <CustomTooltip
                units={{
                  merits_rate: "%",
                  jr_leave_rate: "%",
                }}
              />
            }
          />
          <Legend verticalAlign="top" wrapperStyle={{ top: 0, height: 8 }} />

          <Line
            isAnimationActive={false}
            dot={<CustomizedDot />}
            name="JR Merits Rate"
            type="linear"
            dataKey="merits_rate"
            stroke="firebrick"
            activeDot={{ r: 8 }}
          />
          <Line
            isAnimationActive={false}
            name="JR Leave Rate"
            type="linear"
            dataKey="jr_leave_rate"
            stroke="#162c74"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
      <MobileGraphsBanner />
    </GraphContainer>
  );
}

export default RefugeeJRLeaveRatePerYear;
