import { useEffect, useState } from "react";

type RequestState = "loading" | "error" | "success";

// Transforms a duck db query onto a state-like hook
export function useDb(queryPromise: Promise<any>, args: any[] = []) {
  const [state, setState] = useState<RequestState>();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(null);
  const [argsBlueprint, setArgsBlueprint] = useState<string>(
    JSON.stringify(args)
  );

  useEffect(() => {
    setArgsBlueprint(JSON.stringify(args));
  }, [args]);

  useEffect(() => {
    setLoading(true);

    queryPromise.then((data) => {
      setData(data);
      setLoading(false);
      setState("success");
    });
  }, [argsBlueprint]);

  return { state, loading, data };
}
