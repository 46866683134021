import {
  ActualRecognitionRatePerPredictedRecognitionRateTooltip,
  GraphContainer,
  MobileGraphsBanner,
} from "../../../../shared/Common";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  ComposedChart,
  Scatter,
  Line,
  Label,
} from "recharts";
import {
  AVERAGE_COLOR,
  GRAPH_MARGIN,
  IRB_MEMBER_COLOR,
  IRB_MEMBER_LABEL_COLOR,
  TARGET_MEMBER_COLOR,
} from "../../../../util/constants";

function ActualRecognitionRatePerPredictedRecognitionRate({
  data,
  memberName,
  showAverage = false,
  fullScreen = false,
}: {
  data?: {
    rate?: number;
    predicted?: number;
    targetRate?: number;
    targetPredicted?: number;
    average?: number;
    id?: string;
    name?: string;
  }[];
  memberName?: string;
  showAverage?: boolean;
  fullScreen?: boolean;
}) {
  return (
    <GraphContainer
      style={{ fontFamily: "DM Sans" }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={[
            ...(data || []),
            {
              predicted: 0,
              line: 0,
            },
            {
              predicted: 100,
              line: 100,
            },
          ]}
          margin={{
            top: GRAPH_MARGIN,
            right: GRAPH_MARGIN,
            bottom: GRAPH_MARGIN,
            left: GRAPH_MARGIN,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <Tooltip
            content={
              <ActualRecognitionRatePerPredictedRecognitionRateTooltip
                units={{
                  rate: "%",
                  predicted: "%",
                  average: "%",
                  targetRate: "%",
                }}
              />
            }
          />
          <Legend
            formatter={(value: string, entry: any) => {
              const { color } = entry;
              return (
                <span
                  style={{
                    color:
                      value === "IRB Members (20+ decisions)"
                        ? IRB_MEMBER_LABEL_COLOR
                        : color,
                  }}
                >
                  {value}
                </span>
              );
            }}
            verticalAlign="top"
            wrapperStyle={{ top: 0, height: 8 }}
          />

          <XAxis
            dataKey="predicted"
            type="number"
            unit="%"
            label={
              <Label
                value="Predicted Recognition Rate"
                position="bottom"
                style={{ textAnchor: "middle" }}
              />
            }
            domain={[0, 100]}
          />
          <YAxis
            unit="%"
            type="number"
            label={
              <Label
                angle={-90}
                value="Actual Recognition Rate"
                position="insideLeft"
                style={{ textAnchor: "middle" }}
              />
            }
            domain={[0, 100]}
          />

          <Scatter
            name="IRB Members (20+ decisions)"
            dataKey="rate"
            fill={IRB_MEMBER_COLOR}
          />

          {memberName && (
            <Scatter
              name={memberName}
              dataKey="targetRate"
              fill={TARGET_MEMBER_COLOR}
            />
          )}

          {showAverage && (
            <Scatter
              name="Average (All members)"
              dataKey="average"
              fill={AVERAGE_COLOR}
            />
          )}

          <Line
            dataKey="line"
            stroke="var(--grey-1)"
            dot={false}
            activeDot={false}
            legendType="none"
          />
        </ComposedChart>
      </ResponsiveContainer>
      <MobileGraphsBanner />
    </GraphContainer>
  );
}

export default ActualRecognitionRatePerPredictedRecognitionRate;
