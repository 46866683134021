import styled from "styled-components";
import { Detail, Stat } from "../shared/Common";
import CountUp from "react-countup";

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

const PercentAvg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;
  min-width: 112px;
  max-width: max-content;
  background-color: var(--dark-1);
`;

function StatAvg({
  stat,
  avg,
  label = "*predicted",
}: {
  stat: number;
  avg?: number;
  label?: string;
}) {
  return (
    <StatContainer
      style={{ justifyContent: avg ? "space-between" : "flex-end" }}
    >
      <Stat>
        <CountUp preserveValue decimals={1} end={stat} duration={0.25} />%
      </Stat>
      {avg && (
        <PercentAvg>
          <Detail>{label}</Detail>
          <h2>
            <CountUp preserveValue decimals={1} end={avg} duration={0.25} />%
          </h2>
        </PercentAvg>
      )}
    </StatContainer>
  );
}

export default StatAvg;
