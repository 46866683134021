import { Container } from "@mui/material";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../assets/logo-light-color.png";
import OsgoodeLogo from "../assets/orgs/osgoode.png";
import SSHRCLogo from "../assets/orgs/sshrc.png";
import LawFoundationOfOntarioLogo from "../assets/orgs/law-foundation-of-ontario.png";
import { Detail, Action } from "../shared/Common";

const Layer = styled.div`
  background-color: var(--dark-1);
  padding: 24px 0;
  color: var(--light-1);

  p {
    margin-top: 0;
    margin-bottom: 8px;
  }

  p,
  label,
  button {
    color: var(--light-1);
  }

  animation,
  a:visited {
    color: var(--light-1);
    font-family: DM Sans;
    font-weight: 700;
  }

  padding-bottom: 72px;

  @media (max-width: 600px) {
    padding-bottom: 116px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 164px;
`;

const BottomContent = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    margin-top: 12px;
  }
`;

const OrgLogos = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 12px;
  margin-top: 4px;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: var(--light-1);
  padding: 12px;
  width: max-content;

  a {
    height: 32px;

    img {
      height: 32px;

      @media (max-width: 600px) {
        object-fit: contain;

        :last-child {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    grid-gap: 8px;
    padding: 8px;
  }
`;

function Footer() {
  return (
    <Layer>
      <Container
        style={{
          height: "100%",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Content>
          <Column>
            <a style={{ width: "164px" }} href="https://refugeelab.ca/">
              <Logo src={logo} />
            </a>
          </Column>
          <div
            style={{
              display: "flex",
            }}
          >
            <Column>
              <a href="https://twitter.com/RefugeeLawLab">
                <Action>Twitter</Action>
              </a>
              <a href="https://refugeelab.ca/contact/">
                <Action>Contact</Action>
              </a>
            </Column>
            <Column style={{ marginLeft: "16px" }}>
              <a href="https://refugeelab.ca/website-privacy-policy/">
                <Action>Privacy Policy</Action>
              </a>
              <Link to="/terms-of-use">
                <Action>Terms of Use</Action>
              </Link>
            </Column>
          </div>
        </Content>
        <hr style={{ backgroundColor: "var(--light-1)" }} />
        <BottomContent>
          <Detail
            style={{
              fontWeight: "300",
              fontSize: "10px",
            }}
          >
            This project draws on research supported by
          </Detail>
          <OrgLogos>
            <a href="https://www.osgoode.yorku.ca/">
              <img alt="Osgoode Hall Law School Logo" src={OsgoodeLogo} />
            </a>
            <a href="https://lawfoundation.on.ca/">
              <img
                alt="Law Foundation of Ontario logo"
                src={LawFoundationOfOntarioLogo}
              />
            </a>
            <a href="https://www.sshrc-crsh.gc.ca/">
              <img
                alt="Social Sciences and Humanities Research Council of Canada"
                src={SSHRCLogo}
              />
            </a>
          </OrgLogos>
        </BottomContent>
      </Container>
    </Layer>
  );
}

export default Footer;
