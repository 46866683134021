import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { $Member } from "../util/interfaces";
import { Actions, Detail, EmptyResults } from "../shared/Common";

const MEMBERS_PAGE_OFFSET = 10;

const Box = styled.div`
  display: grid;
  grid-row-gap: 8px;

  a,
  a:visited {
    color: var(--dark-1);
    width: max-content;
  }

  hr {
    transform: translateY(8px);
  }
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`;

function MemberCard({ member }: { member: $Member }) {
  const {
    firstName,
    lastName,
    memberId: nameId,
    caseCount,
    predicted,
    rate,
  } = member;

  return (
    <Box>
      <a href={`immigration-refugee-board/member/${nameId}`}>
        <b>{`${firstName} ${lastName}`}</b>
      </a>
      <Info>
        <InfoItem>
          <Detail>Recognition rate</Detail>
          <p>{rate}%</p>
        </InfoItem>
        <InfoItem>
          <Detail>Predicted recognition rate</Detail>
          <p>{predicted}%</p>
        </InfoItem>
        <InfoItem>
          <Detail>Claims</Detail>
          <p>{caseCount}</p>
        </InfoItem>
      </Info>
      <hr />
    </Box>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const MembersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 24px;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  margin: 16px 0;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;

function Members({ members }: { members: $Member[] }) {
  const [page, setPage] = useState(1);
  const [pageMembers, setPageMembers] = useState<$Member[]>([]);

  useEffect(() => {
    const actualPage = page - 1;
    setPageMembers(
      members.slice(
        actualPage * MEMBERS_PAGE_OFFSET,
        actualPage * MEMBERS_PAGE_OFFSET + MEMBERS_PAGE_OFFSET
      )
    );
  }, [page, members]);

  useEffect(() => {
    setPage(1);
  }, [members]);

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Content>
      {!members.length ? (
        <EmptyResults message="No members found" />
      ) : (
        <MembersContainer>
          {pageMembers.map((member, index) => (
            <MemberCard key={`member-${index}`} member={member} />
          ))}
        </MembersContainer>
      )}
      <Actions style={{ marginTop: "16px", justifyContent: "center" }}>
        <Pagination
          onChange={handlePageChange}
          page={page}
          count={Math.ceil(members.length / MEMBERS_PAGE_OFFSET)}
          color="primary"
        />
      </Actions>
    </Content>
  );
}

export default Members;
