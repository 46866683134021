import styled from "styled-components";
import { Detail, Subtitle, Title } from "../../../shared/Common";
import { convertToolTipLabel } from "../../../util/helpers";

export const TooltipBox = styled.div`
  padding: 12px 16px;
  background-color: var(--light-1);
  border: solid 1px var(--dark-1);
`;

const TooltipLabel = styled.div`
  display: flex;
  align-items: center;
`;

const LinkBox = styled.a`
  pointer-events: all;
  position: absolute;
  left: -32px;
  top: -32px;
  height: calc(100% + 64px);
  width: calc(100% + 64px);
  cursor: pointer;
`;

export const LeaveRatePerOverallJRRateLeaveJudgeToolTip = ({
  active,
  payload,
  units,
}: {
  active?: any;
  payload?: any;
  units: any;
}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const label = payload[0].name;
    const id = data.id;
    const color = payload[0].color;
    const chartKeys = Object.keys(data).filter((key) => key !== "id");

    if (Object.hasOwn(data, "line")) {
      return null;
    }

    return (
      <TooltipBox>
        {Object.hasOwn(data, "average") ||
        Object.hasOwn(data, "targetRate") ? null : (
          <LinkBox
            target="_blank"
            href={`/federal-court/judge/${id}`}
          ></LinkBox>
        )}
        {chartKeys.map((key, index) => (
          <div key={`${key}-${index}`}>
            <Subtitle>{convertToolTipLabel(key, "fc")}</Subtitle>
            <Title
              style={{
                marginBottom: index === chartKeys.length - 1 ? "0px" : "8px",
              }}
            >
              {data[key]} {units[key] || ""}
            </Title>
          </div>
        ))}
        {payload.length === 1 && (
          <>
            <hr />
            <TooltipLabel>
              <div
                style={{
                  backgroundColor: color,
                  height: "12px",
                  width: "12px",
                  borderRadius: "100%",
                  marginRight: "4px",
                }}
              />
              <Detail>{label}</Detail>
            </TooltipLabel>
          </>
        )}
      </TooltipBox>
    );
  }

  return null;
};
