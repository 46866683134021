import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

function UseIndicator(start: string) {
  const history = useHistory();
  const [activeSection, setActiveSection] = useState(start);
  const [indicatorOffsetY, setIndicatorOffsetY] = useState(0);
  const [indicatorHeight, setIndicatorHeight] = useState(0);

  useEffect(() => {
    const section = history.location.hash.slice(1);
    setActiveSection(section);
  }, [history]);

  useEffect(() => {
    history.listen(({ hash }) => {
      const newSection = hash.slice(1);
      if (newSection !== activeSection) {
        setActiveSection(newSection);
      }
    });
  }, [history, activeSection]);

  useEffect(() => {
    const targetQuery = `a[href='#${activeSection}']`;
    const targetElement = document.querySelector(targetQuery);

    if (targetElement) {
      const { y, height } = targetElement.getBoundingClientRect();
      if (indicatorOffsetY !== y) {
        setIndicatorOffsetY(y - 148);
        setIndicatorHeight(height);
      }
    }
  }, [activeSection, indicatorOffsetY]);

  return { indicatorHeight, indicatorOffsetY };
}

export default UseIndicator;
